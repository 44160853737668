
import { types } from "./types";


export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                logged: true,
                user: action.payload
            }

        case types.LOGOUT:
            return {
                logged: false
            }


        case types.SWITHC_THEME:
            return {
                ...state,
                theme: action.payload
            }

        case types.SWITCH_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }

        case types.SWITCH_DEVICE:
            return {
                ...state,
                device: action.payload
            }


        case types.PREVIOUS_CONFIG:
            return {
                ...state,
                previousConfigs: action.payload
            }

        default:
            return state
    }
} 