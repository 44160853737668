import { Button, Col, Empty, Image, Layout, Row, DatePicker, Select, Input, Table, Spin, Tag, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { appContext } from '../../context/AuthContext';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { t } from 'i18next';
import { formatDate, formatDocument, handleComments, handleHeaders } from '../../helpers/functions';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { AiOutlineEye, AiFillEye, AiOutlineEyeInvisible, AiOutlineSearch } from "react-icons/ai";
import { BiHelpCircle, BiRefresh } from "react-icons/bi";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { AllErrors, AllRevieweds, MyErrors, MyRevieweds, allTestByState, getCountries, getDifficulties, getDocuments, getTest, myTestByState } from '../../services/apiServices';
import useAxios from '../../Hooks/UseAxios';
import { ReviewTestDrawer } from '../../helpers/drawer';
import { logos } from '../../helpers/logo';
import { IoMdHelp } from "react-icons/io";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select

export const ContentReviews = ({ collapsed, setCollapsed, setSearch, setViewID, search }) => {


    const { theme } = useContext(appContext)
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState(data)
    const [columns, setColumns] = useState(null)
    const [state, setState] = useState('READY')
    const [myTests, setMyTests] = useState(true)
    const { response, loading, error, operation } = useAxios()

    const [filterCountry, setFilterCountry] = useState([])
    const [filterDocuments, setFilterDocuments] = useState([])
    const [filterDifficulty, setFilterDifficulty] = useState([])
    const [curentTest, setCurentTest] = useState({})
    const [date, setDate] = useState(null)
    const [finalDate, setFinalDate] = useState(null)
    const [open, setOpen] = useState(false)
    const [identityID, setIdentityID] = useState('')
    const [onHover, setOnHover] = useState(false)
    const [onHover_, setOnHover_] = useState(false)
    const [render, setRender] = useState(false)

    const Mycolumns = [

        {
            title: `${t('Random.Table.Date')}`,
            width: '10%',
            dataIndex: 'ShipmentDate',
            key: 'ShipmentDate',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{formatDate(text)}</p>
        },
        {
            title: `${t('LatestShipments.identityID')}`,
            width: '20%',
            dataIndex: 'IdentityID',
            key: 'IdentityID',
            // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            //     <div style={{
            //         padding: 8, backgroundColor: `${theme ? '#fff' : '#222326'}`,
            //         border: `${theme ? '' : '2px solid #000'}`,
            //     }}>
            //         <Input
            //             placeholder="Search by identity ID"
            //             value={selectedKeys[0]}
            //             className='inputs-filters'
            //             onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            //             onPressEnter={confirm}
            //             style={{
            //                 width: 188, marginBottom: 8, display: 'block',
            //                 backgroundColor: `${theme ? '#fff' : '#2b2c2e'}`,
            //                 border: `${theme ? '' : '2px solid #000'}`,
            //                 color: `${theme ? '' : '#AAA'}`,

            //             }}
            //         />
            //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            //             <Button
            //                 type="primary"
            //                 onClick={confirm}
            //                 icon={<SearchOutlined />}
            //                 size="small"
            //                 style={{
            //                     width: 90, color: `${theme ? '' : '#fff'}`,
            //                     backgroundColor: `${theme ? '' : '#9078b5'}`
            //                 }}
            //             >
            //                 Search
            //             </Button>
            //             <Button onClick={clearFilters} size="small" style={{
            //                 width: 90, border: `${theme ? '' : '1.5px solid #000'}`,
            //                 backgroundColor: `${theme ? '' : '#2b2c2e'}`, color: `${theme ? '' : '#AAA'}`
            //             }}>
            //                 Clean
            //             </Button>
            //         </div>
            //     </div>
            // ),
            // filterIcon: (filtered) => (
            //     <SearchOutlined style={{ color: '#00000050' }} />
            // ),
            // onFilter: (value, record) => record.IdentityId.toLowerCase().includes(value.toLowerCase()),
            render: (value) =>
                <p style={{
                    fontWeight: 400, color: `${theme ? '' : '#AAA'}`,
                    textAlign: 'center'
                }}>{value}</p>
        },
        {
            title: `${t('LatestShipments.country')}`,
            width: '12%',
            dataIndex: 'Country',
            key: 'Country',
            filters: filterCountry,
            filterSearch: true,
            onFilter: (value, record) => record.Country.includes(value),
            onFilter: (text, record) => record.Country === text,
            // filterDropdown
            sorter: (a, b) => a.Country.localeCompare(b.Country),
            sortDirections: ['ascend'],
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('LatestShipments.document')}`,
            width: '12%',
            dataIndex: 'Document',
            key: 'Document',
            filters: filterDocuments,
            filterSearch: true,
            onFilter: (value, record) => formatDocument(record.Document).includes(value),
            onFilter: (text, record) => formatDocument(record.Document) === text,
            sorter: (a, b) => a.Document.localeCompare(b.Document),
            sortDirections: ['ascend'],
            render: (text) =>
                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    {text}
                </div>
        },
        {
            title: `${t('Random.setConfig.difficulty')}`,
            width: '12%',
            dataIndex: 'Difficulty',
            key: 'Difficulty',
            filters: filterDifficulty,
            filterSearch: true,
            onFilter: (value, record) => (record.Difficulty).includes(value),
            onFilter: (text, record) => (record.Difficulty) === text,
            sorter: (a, b) => a.Difficulty.localeCompare(b.Difficulty),
            sortDirections: ['ascend'],
            render: (text) =>
                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    {text}
                </div>
        },
        {
            title: `${t('Random.setConfig.Actions')}`,
            width: '17%',
            dataIndex: 'Status',
            key: 'Status',
            fixed: 'right',
            render: (_, record) => (
                <Row style={{
                    width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'row'
                }}>

                    <Button
                        onClick={() => onReviewing(record.IdentityID)}
                        // onClick={() => message.info('Reviews will be available soon.')}
                        onMouseEnter={() => setOnHover(!onHover)}
                        className={onHover ? 'prev' : 'prev_'}
                        disabled={_ === 'READY' ? false : true}
                        style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: theme ? '#EBF1FC' : _ === 'READY' ? '#393B3F' : '#393B3F', fontWeight: 400,
                            borderRadius:'3vh',
                            border: theme ? '0px solid #000' : '0px solid #cbdaf860',
                            color: theme ? '' : '#AAA'
                        }}
                    // icon={<BsBoxArrowUpRight />}
                    >{t('ViewTest.Reviewnow')}</Button>

                    <Button
                        onClick={() => onViewTest(record.IdentityID)}
                        onMouseEnter={() => setOnHover_(!onHover_)}
                        className={onHover_ ? 'prev' : 'prev_'}
                        icon={<AiOutlineEye size={15} style={{ color: theme ? '' : '#AAA' }} />}
                        style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: theme ? '#EBF1FC' : '#393B3F', fontWeight: 400,
                            border: theme ? '0px solid #000' : '0px solid #cbdaf860', marginLeft: '10px',
                            borderRadius:'50%',
                        }} />
                </Row>
            )
        },

    ]
    const Allcolumns = [

        {
            title: `${t('Random.Table.Date')}`,
            width: '10%',
            dataIndex: 'ShipmentDate',
            key: 'ShipmentDate',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{formatDate(text)}</p>
        },
        {
            title: `Tester`,
            width: '15%',
            dataIndex: 'SentBy',
            key: 'SentBy',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('LatestShipments.identityID')}`,
            width: '15%',
            dataIndex: 'IdentityID',
            key: 'IdentityID',
            // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            //     <div style={{
            //         padding: 8, backgroundColor: `${theme ? '#fff' : '#222326'}`,
            //         border: `${theme ? '' : '2px solid #000'}`,
            //     }}>
            //         <Input
            //             placeholder="Search by identity ID"
            //             value={selectedKeys[0]}
            //             className='inputs-filters'
            //             onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            //             onPressEnter={confirm}
            //             style={{
            //                 width: 188, marginBottom: 8, display: 'block',
            //                 backgroundColor: `${theme ? '#fff' : '#2b2c2e'}`,
            //                 border: `${theme ? '' : '2px solid #000'}`,
            //                 color: `${theme ? '' : '#AAA'}`,

            //             }}
            //         />
            //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            //             <Button
            //                 type="primary"
            //                 onClick={confirm}
            //                 icon={<SearchOutlined />}
            //                 size="small"
            //                 style={{
            //                     width: 90, color: `${theme ? '' : '#fff'}`,
            //                     backgroundColor: `${theme ? '' : '#9078b5'}`
            //                 }}
            //             >
            //                 Search
            //             </Button>
            //             <Button onClick={clearFilters} size="small" style={{
            //                 width: 90, border: `${theme ? '' : '1.5px solid #000'}`,
            //                 backgroundColor: `${theme ? '' : '#2b2c2e'}`, color: `${theme ? '' : '#AAA'}`
            //             }}>
            //                 Clean
            //             </Button>
            //         </div>
            //     </div>
            // ),
            // filterIcon: (filtered) => (
            //     <SearchOutlined style={{ color: '#00000050' }} />
            // ),
            // onFilter: (value, record) => record.IdentityId.toLowerCase().includes(value.toLowerCase()),
            render: (value) =>
                <p style={{
                    fontWeight: 400, color: `${theme ? '' : '#AAA'}`,
                    textAlign: 'center'
                }}>{value}</p>
        },
        {
            title: `${t('LatestShipments.country')}`,
            width: '12%',
            dataIndex: 'Country',
            key: 'Country',
            filters: filterCountry,
            filterSearch: true,
            onFilter: (value, record) => record.Country.includes(value),
            onFilter: (text, record) => record.Country === text,
            // filterDropdown
            sorter: (a, b) => a.Country.localeCompare(b.Country),
            sortDirections: ['ascend'],
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('LatestShipments.document')}`,
            width: '12%',
            dataIndex: 'Document',
            key: 'Document',
            filters: filterDocuments,
            filterSearch: true,
            onFilter: (value, record) => formatDocument(record.Document).includes(value),
            onFilter: (text, record) => formatDocument(record.Document) === text,
            sorter: (a, b) => a.Document.localeCompare(b.Document),
            sortDirections: ['ascend'],
            render: (text) =>
                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    {text}
                </div>
        },
        {
            title: `${t('Random.setConfig.difficulty')}`,
            width: '12%',
            dataIndex: 'Difficulty',
            key: 'Difficulty',
            filters: filterDifficulty,
            filterSearch: true,
            onFilter: (value, record) => (record.Difficulty).includes(value),
            onFilter: (text, record) => (record.Difficulty) === text,
            sorter: (a, b) => a.Difficulty.localeCompare(b.Difficulty),
            sortDirections: ['ascend'],
            render: (text) =>
                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    {text}
                </div>
        },
        {
            title: `${t('Random.setConfig.Actions')}`,
            width: '18%',
            dataIndex: 'Status',
            key: 'Status',
            fixed: 'right',
            render: (_, record) => (
                <Row style={{
                    width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'row'
                }}>
                    <Button
                        onClick={() => onReviewing(record.IdentityID)}
                        // onClick={() => message.info('Reviews will be available soon.')}
                        onMouseEnter={() => setOnHover(!onHover)}
                        className={onHover ? 'prev' : 'prev_'}
                        disabled={_ === 'READY' ? false : true}
                        style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: theme ? '#EBF1FC' : _ === 'READY' ? '#393B3F' : '#393B3F', fontWeight: 400,
                            borderRadius:'3vh',
                            border: theme ? '0px solid #000' : '0px solid #cbdaf860',
                            color: theme ? '' : '#AAA'
                        }}
                    // icon={<BsBoxArrowUpRight />}
                    >{t('ViewTest.Reviewnow')}</Button>

                    <Button
                        onClick={() => onViewTest(record.IdentityID)}
                        onMouseEnter={() => setOnHover_(!onHover_)}
                        className={onHover_ ? 'prev' : 'prev_'}
                        icon={<AiOutlineEye size={15} style={{ color: theme ? '' : '#AAA' }} />}
                        style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: theme ? '#EBF1FC' : '#393B3F', fontWeight: 400,
                            border: theme ? '0px solid #000' : '0px solid #cbdaf860', marginLeft: '10px',
                            borderRadius:'50%',
                        }} />
                </Row>
            )
        },
    ]

    const findTest = (state) => {
        if (state) {
            if (!search) {
                setCollapsed(true)
                setTimeout(() => {
                    setSearch(state)
                    setCollapsed(false)
                }, 500);

            } else {
                setCollapsed(false)
                setSearch(state)
            }

        }

        else {
            if (search) {
                setCollapsed(true)
                setTimeout(() => {
                    setSearch(state)
                    setCollapsed(false)
                }, 500);
            } else {
                setCollapsed(false)
                setSearch(state)
            }

        }
    }

    const handleTests = (test) => {
        if (test === 'My tests') {
            setColumns(Mycolumns)
            setData([])
            setMyTests(true)
            if (state === 'SENT' || state === 'READY') {
                myTestByState(operation, state)
            } else if (state === 'REVIEWED') {
                MyRevieweds(operation, date, finalDate)
            } else {
                MyErrors(operation, date, finalDate)
            }
        }

        else {
            setColumns(Allcolumns)
            setData([])
            setMyTests(false)
            if (state === 'SENT' || state === 'READY') {
                allTestByState(operation, state)
            } else if (state === 'REVIEWED') {
                AllRevieweds(operation, date, finalDate)
            } else {
                AllErrors(operation, date, finalDate)
            }
        }
    }

    const getInitialData = async () => {
        try {
            await Promise.all([
                getCountries(operation),
                getDocuments(operation),
                getDifficulties(operation)
            ]);
        } catch { }

    }

    const onViewTest = (id) => {
        setViewID(id)
        findTest(false)
    }

    const onReviewing = (id) => {
        setIdentityID(id)
        setOpen(true)
    }

    const fetchSimpleDate = () => {
        const today = new Date();
        today.setHours(today.getHours() - 6);
        const formattedDate = today.toISOString().split('T')[0];
        setDate(formattedDate)
        setFinalDate(formattedDate)

    };

    const applyFilter = (dates) => {
        if (dates) {
            setDate(dates[0].format('YYYY-MM-DD'))
            setFinalDate(dates[1].format('YYYY-MM-DD'))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getInitialData()
            } catch (error) {
                message.error(`${t('Shipments.Error.message')}`)
            }
        };
        setRender(true)
        fetchData()
        fetchSimpleDate()
        // setColumns(Mycolumns)
    }, [])

    useEffect(() => {
        if (filterCountry && filterDocuments && filterDifficulty) {
            if (filterCountry.length === 0 || filterDocuments.length === 0 || filterDifficulty.length === 0) {
                getInitialData()
            }

            else {
                if (myTests) setColumns(Mycolumns)
                else setColumns(Allcolumns)
                setRender(false)
            }
        }
    }, [filterCountry, filterDocuments, filterDifficulty])

    useEffect(() => {
        if (myTests) {

            setData([])
            if (state === 'SENT' || state === 'READY') {
                myTestByState(operation, state)
            } else if (state === 'REVIEWED') {
                MyRevieweds(operation, date, finalDate)
            } else {
                MyErrors(operation, date, finalDate)
            }

        } else {

            setData([])
            if (state === 'SENT' || state === 'READY') {
                allTestByState(operation, state)
            } else if (state === 'REVIEWED') {
                AllRevieweds(operation, date, finalDate)
            } else {
                AllErrors(operation, date, finalDate)
            }

        }
    }, [state, date])


    useEffect(() => {
        if (!loading) {
            switch (response.data.msg) {
                case "Get all countries":
                    const count = response.data.data;
                    // console.log(count)
                    const countryNames = count.map(country => country.Code_Country)
                    const sortedCountryNames = countryNames.sort();
                    setFilterCountry([])
                    const newFilterCountry = sortedCountryNames.map((country) => ({
                        text: `${country}`,
                        value: `${country}`,
                    }))
                    setFilterCountry([...newFilterCountry])
                    break;

                case "Get all documents":
                    const documents = response.data.data;
                    console.log(documents)
                    const documentsName = documents.map(document => document.Type_Document);
                    const sortedDocuments = documentsName.sort();
                    setFilterDocuments([])
                    const newFilterDocument = sortedDocuments.map((doc) => ({
                        text: `${doc === 'NI' ? 'national-id' : doc === 'PS' ? 'passport' : doc === 'DL' ? 'drivers-license' : ''}`,
                        value: `${doc === 'NI' ? 'national-id' : doc === 'PS' ? 'passport' : doc === 'DL' ? 'drivers-license' : ''}`,
                    }))
                    setFilterDocuments([...newFilterDocument])
                    break

                case "Get all difficulties":
                    const difficulties = response.data.data;
                    const filterDifficulties = difficulties.filter(doc => doc.Short_Name !== "E" & doc.Short_Name !== "M" & doc.Short_Name !== "N")
                    const AllDifficulties = filterDifficulties.map(difficulty => difficulty.Short_Name);
                    const sortedDifficulties = AllDifficulties.sort();
                    setFilterDifficulty([])
                    const newFilterDifficulty = sortedDifficulties.map((difficulty) => ({
                        text: `${difficulty}`,
                        value: `${difficulty}`,
                    }))
                    setFilterDifficulty([...newFilterDifficulty])
                    break

                case "My tests by state":
                    if (response.data.data) {
                        const values = response.data.data;
                        const reversedTests = values.slice().reverse();
                        const newTests = reversedTests.map((test) => ({
                            IdentityID: test.Identity_Id,
                            Difficulty: test.Difficulty,
                            Country: test.Country[0],
                            Document: test.Document[0],
                            ShipmentDate: test.Shipment_Date,
                            SentBy: test.CreatedBy[0],
                            Status: test.Status,
                            key: test._id
                        }));
                        // setColumns(Mycolumns)
                        setData(newTests);
                    }
                    break;


                case "All tests by state":
                    if (response.data.data) {
                        const values = response.data.data;
                        const reversedTests = values.slice().reverse();
                        const newTests = reversedTests.map((test) => ({
                            IdentityID: test.Identity_Id,
                            Difficulty: test.Difficulty,
                            Country: test.Country[0],
                            Document: test.Document[0],
                            ShipmentDate: test.Shipment_Date,
                            SentBy: test.CreatedBy[0],
                            Status: test.Status,
                            key: test._id
                        }));
                        // setColumns(Allcolumns)
                        setData(newTests);
                    }
                    break;



                default:
                    break;
            }
            // setTimeout(() => {
            //     setTableReady(true)
            // }, 1000);
        }
    }, [response])

    useEffect(() => {
        if (myTests) {
            setColumns(Mycolumns)
        } else setColumns(Allcolumns)
    }, [theme])



    return (
        <>
            <Content
                // className='scrollable-div'
                className='scrollable-div'
                // className={theme ? '' : 'dark-scroll'}
                style={{
                    marginTop: '12vh', // Ajusta este valor para que el contenido no quede detrás del header
                    // marginBottom: '2vh', // Ajusta este valor para que el contenido no quede detrás del footer
                    // overflowY: 'scroll',
                    height: '80vh', // Ajusta estos valores según el header y footer
                    flexDirection: 'column', padding: '0 0 0 0',
                    backgroundColor: 'transparent',
                    display: 'flex', padding: '2%',
                    alignItems: 'center', justifyContent: 'flex-start',
                    // alignItems: data.length > 0 ? 'flex-start' : 'center', justifyContent: data.length > 0 ? 'flex-start' : 'center', flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
            >
                <div style={{
                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                    // margin: '1vh 0 3vh 0'
                }}>
                    {
                        handleHeaders(state, theme, t)
                    }

                    <Button
                        // type='ghost'
                        onClick={() => findTest(true)}
                        icon={<IoMdHelp style={{ color: !collapsed ? !search ? theme ? '' : '#AAA' : theme ? '' : '#000' : theme ? '' : '#AAA', }} />}
                        // icon={<IoIosHelpCircleOutline size={20} style={{ marginRight: '5px' }} />}
                        style={{
                            // fontWeight: 400, fontSize: '1.1em', flexDirection: 'row', 
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            borderRadius: '50%',
                            // height: '4.5vh',
                            backgroundColor: !collapsed ? !search ? theme ? '#F5F5F5' : '#28292C' : theme ? '#cbdaf860' : 'rgba(170,170,170,0.9)' : theme ? '#F5F5F5' : '#28292C',
                            border: theme ? '0px solid #AAAAAA30' : '1px solid #00000020',
                            color: theme ? '' : '#aaaaaa80',
                            // marginRight: '45px'
                        }} />
                </div>



                <div style={{
                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                    margin: '1vh 0 3vh 0'
                }}>

                    {
                        handleComments(state, data, theme, t, date, finalDate)
                    }



                </div>

                <hr
                    // onClick={() => getInitialData()}
                    style={{
                        width: '100%', border: '1px solid #00000022', cursor: 'pointer'
                    }} />

                <Row style={{
                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                    margin: '3vh 0 3vh 0'
                }}>
                    <Row style={{
                        width: '70%', display: 'flex', alignItems: 'center',
                        justifyContent: state === 'SENT' || state === 'READY' ? 'flex-start' : 'space-between',
                        flexDirection: 'row',

                    }}>
                        <Select
                            // value={myTests}
                            onChange={handleTests}
                            placeholder={`${t('Reviews.select.mytests')}`}
                            dropdownStyle={{
                                backgroundColor: theme ? '' : '#CCD5DC'
                            }}
                            className={`${theme ? 'my-tests-select-2' : 'my-tests-select-dark-2'}`}
                            popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                            style={{
                                width: '32%', height: '4.5vh',
                                marginRight: state === 'SENT' || state === 'READY' ? '18px' : 0
                            }}
                        >
                            <Option key={'test'} value={'My tests'}>
                                {t('Reviews.select.mytests')}
                            </Option>

                            <Option key={'all'} value={"All tests"}>
                                {t('Reviews.select.alltests')}
                            </Option>
                        </Select>

                        <Select
                            // value={myTests}
                            // onChange={(e) => setMyTests(e)}
                            onChange={(e) => setState(e)}
                            dropdownStyle={{
                                backgroundColor: theme ? '' : '#CCD5DC'
                            }}
                            placeholder={`${t('State.reviewed')}`}
                            value={state}
                            className={`${theme ? 'my-tests-select-2' : 'my-tests-select-dark-2'}`}
                            popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                            style={{
                                width: '32%', height: '4.5vh',
                            }}
                        >
                            <Option key={'sent'} value={'SENT'}>
                                {t('State.Sent')}
                            </Option>

                            <Option key={'ready'} value={"READY"}>
                                {t('State.Ready')}
                            </Option>

                            <Option key={'reviewed'} value={"REVIEWED"}>
                                {t('State.Reviewed')}
                            </Option>

                            <Option key={'error'} value={"ERROR"}>
                                {t('State.Error')}
                            </Option>
                        </Select>

                        <RangePicker
                            className='picker'
                            // disabled={state === 'SENT' || state === 'READY' ? true : false}
                            onChange={applyFilter}
                            // dropdownAlign={{backgroundColor:'red'}}
                            style={{
                                height: '4.5vh',
                                borderRadius: '3vh',
                                width: '32%',
                                display: state === 'SENT' || state === 'READY' ? 'none' : '',
                                backgroundColor: `${theme ? '#ffffff90' : '#2B2D31'}`,
                                // boxShadow: `0px 0px 8px #000000${theme ? '22' : '80'}`,
                                border: theme ? '' : '1px solid #00000080',
                                color: `${theme ? '' : '#AAA !important'}`,
                            }} />
                    </Row>



                </Row>

                {
                    !render ?
                        <Table
                            style={{
                                width: '100%',
                                height: '50%',
                                borderRadius: '0vh'
                            }}
                            // className={}
                            className={`${theme ? 'latest-shipments' : 'my-table-revship'}`}
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                y: '45vh',
                                x: myTests ? '80vw' : '90vw'
                            }}
                            pagination={false}
                        />

                        : <div style={{ height: '40vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                            <img src={logos.rainbow} style={{ marginTop: '-10vh', width: '8%' }} />
                            {/* <img src="https://giphy.com/embed/xTk9ZvMnbIiIew7IpW" size={200} alt='GIF' /> */}


                        </div>
                }




            </Content>

            <ReviewTestDrawer theme={theme} identityID={identityID} drawer={open} setDrawer={setOpen} setState={setState} />
        </>
    )
}


{/* <Row style={{
                    width: '22%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'
                }}>
                    <Select placeholder="MX"
                        onChange={filterCountry}
                        // value={country}
                        className={`${theme ? 'random-light' : 'random-dark'}`}
                        style={{ width: '33%', }}
                        // dropdownStyle={{backgroundColor: '#333437', color: '#496177'}}
                        popupClassName={`${theme ? '' : 'custom-dropdown'}`}

                    >
                        {

                            JSON.parse(localStorage.getItem('countries')).map((country) => (
                                <Option key={country} value={country}>
                                    {country}
                                </Option>
                            ))
                        }

                    </Select>

                    <Select placeholder="NI"
                        onChange={filterDocument}
                        className={`${theme ? 'random-light' : 'random-dark'}`}
                        // value={document}
                        popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                        style={{ width: '33%' }}>
                        {
                            JSON.parse(localStorage.getItem('documents')).map((doc) => (
                                <Option key={doc} value={doc}>
                                    {doc}
                                </Option>
                            ))
                        }

                    </Select>

                    <Select
                        // mode='multiple'
                        onChange={filterDifficulty}
                        // value={difficulties}
                        placeholder={'A'}
                        className={`${theme ? 'random-light' : 'random-dark'}`}
                        popupClassName={`${theme ? '' : 'custom-dropdown'}`}

                        style={{ width: '33%', }}>
                        {
                            JSON.parse(localStorage.getItem('difficulties')).map((grad) => (
                                grad != null ?
                                    <Option key={grad} value={grad}>
                                        {grad}
                                    </Option>
                                    : <></>
                            ))
                        }

                    </Select>
                </Row> */}