
import { Layout, Button, Avatar, Menu, Switch, Row, Dropdown, message } from "antd"

import { UserOutlined } from '@ant-design/icons';
import { IoLanguageOutline } from "react-icons/io5";
import { IoMoonOutline } from "react-icons/io5";
import { BsFillBrightnessHighFill, BsFillMoonFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import {
    BsPersonVcard, BsPersonVcardFill, BsEnvelope,
    BsClipboardCheck, BsClipboardCheckFill,
    BsEnvelopeOpenFill, BsHouse, BsFillHouseFill,
    BsEnvelopeFill
} from "react-icons/bs";
import { logos } from "../../helpers/logo";
import { handleColors } from "../../helpers/functions";
import { appContext } from "../../context/AuthContext";
import { FaRegUser, FaUser } from "react-icons/fa";
import { IoLogOutSharp } from "react-icons/io5";
import { IoLanguage } from "react-icons/io5";
import { MdHub, MdOutlineWorkOutline, MdEmail, MdOutlineHub, MdOutlineEmail, MdOutlineWbSunny, MdWork } from "react-icons/md";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoSettingsOutline, IoSettingsSharp } from "react-icons/io5";
import { colors } from "../../helpers/colors";
const { Header } = Layout


export const HeaderApp = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const {
        logout, handleLanguage, handleTheme, language, user, theme,
        handleDevice, device
    } = useContext(appContext)

    const [logoutbg, setLogoutbg] = useState(false)
    const [themebg, setThemebg] = useState(false)
    const [languagebg, setLanguagebg] = useState(false)
    const [userbg, setUserbg] = useState(false)
    const [devicebg, setDevicebg] = useState(false)
    const [visible, setVisible] = useState(false)
    const [onHome, setOnHome] = useState(false)
    const [onShipment, setOnShipment] = useState(false)
    const [onReviews, setOnReviews] = useState(false)
    const [onTitle, setOnTitle] = useState(false)
    const [onAvatar, setOnAvatar] = useState(false)
    const [onDevice, setOnDevice] = useState(false)
    const [selectDevice, setselectDevice] = useState(device)
    const [listener, setListener] = useState(false)

    // const handleReturn = () => {
    //     logout()
    //     // navigate("/login", {
    //     //     replace: true
    //     // })
    // }

    const changeTheme = (theme) => {
        setselectDevice(false)
        handleTheme(theme)
    }

    const menu = (

        <>
            {
                user ?
                    <Menu style={{
                        backgroundColor: `${theme ? '#f1f1f1' : '#292a2e'}`, width: 300,
                        marginTop: '15px', borderRadius: '3vh', marginRight: '-50px'

                    }}>
                        <Menu.Item
                            onMouseEnter={() => setUserbg(true)} onMouseLeave={() => setUserbg(false)}
                            style={{
                                color: `${theme ? '' : (userbg ? '#e3e3e3' : '#e3e3e3')}`, cursor: 'default', borderRadius: '3vh 3vh 0 0',
                                backgroundColor: `${theme ? (userbg ? '#a8c1f480' : '#fff') : (userbg ? '#a8c1f460' : '#333437')}`,
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', marginTop: '0' }}>
                                <Avatar size={50} src={user.profilePicture} icon={<UserOutlined />}
                                    style={{ marginLeft: 0, marginRight: 15, borderRadius: '50%' }} />
                                <p style={{
                                    fontWeight: 'bold', wordBreak: 'break-word',
                                    textAlign: 'left', fontSize: '1.1em', margin: '0'
                                }}>{user.name}</p>
                            </div>
                            <hr style={{ width: '100%', border: `1px solid ${theme ? (userbg ? '#292a2e' : '#e3e3e3') : '#292a2e'}` }} />
                            <ul style={{ listStyle: 'none', marginTop: '0.8em', marginBottom: '1vh', marginLeft: -25 }}>

                                {
                                    !userbg ?
                                        <>
                                            <li style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                                            }}><MdOutlineEmail size={15} style={{
                                                marginRight: '20px', fontWeight: 'bolder'
                                            }} />{user.username}</li>
                                            <li style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                                            }}><MdOutlineHub size={15} style={{
                                                margin: '10px 20px 10px 0px', fontWeight: 'bolder'
                                            }} />{user.hub}</li>
                                            <li style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                                            }}><MdOutlineWorkOutline size={15} style={{
                                                marginRight: '20px', fontWeight: 'bolder'
                                            }} />{user.role}</li>
                                        </>
                                        :
                                        <>
                                            <li style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                                            }}><MdEmail size={15} style={{
                                                marginRight: '20px', fontWeight: 'bolder'
                                            }} />{user.username}</li>
                                            <li style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                                            }}><MdHub size={15} style={{
                                                margin: '10px 20px 10px 0px', fontWeight: 'bolder'
                                            }} />{user.hub}</li>
                                            <li style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                                            }}><MdWork size={15} style={{
                                                marginRight: '20px', fontWeight: 'bolder'
                                            }} />{user.role}</li>
                                        </>
                                }

                            </ul>

                        </Menu.Item>

                        <Menu.Item
                            onMouseEnter={() => setLanguagebg(true)} onMouseLeave={() => setLanguagebg(false)}
                            style={{
                                color: `${theme ? '' : (languagebg ? '#e3e3e3' : '#e3e3e3')}`, margin: '0.5vh 0 0 0', height: 50, cursor: 'default',
                                backgroundColor: `${theme ? (languagebg ? '#a8c1f480' : '#fff') : (languagebg ? '#a8c1f460' : '#333437')}`,
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', paddingLeft: '1em', paddingRight: '1em' }}>
                                <div style={{ width: '15%' }}>
                                    {
                                        !languagebg
                                            ? <IoLanguageOutline size={15} />
                                            : <IoLanguage size={15} />
                                    }

                                </div>
                                <div style={{ width: '70%', fontStyle: 'italic' }}>

                                    <p>{t('Header.Menu.language')}</p>

                                </div>
                                <div style={{ width: '20%' }}>
                                    <Switch
                                        checked={language}
                                        onChange={() => handleLanguage(!language)}
                                        size="small"
                                        style={{ width: '50%', backgroundColor: language ? theme ? '#a8c1f4' : '#d5d5d5' : '', color: '#000' }}
                                    // style={{  }}
                                    />
                                </div>
                            </div>
                            {/* <hr/> */}
                        </Menu.Item>

                        <Menu.Item
                            onMouseEnter={() => setThemebg(true)} onMouseLeave={() => setThemebg(false)}
                            style={{
                                color: `${theme ? '' : (themebg ? '#e3e3e3' : '#e3e3e3')}`, margin: '0.5vh 0 0.5vh 0', height: 50, cursor: 'default',
                                backgroundColor: `${theme ? (themebg ? '#a8c1f480' : '#fff') : (themebg ? '#a8c1f460' : '#333437')}`,
                            }}>
                            <div style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                                paddingLeft: '1em', paddingRight: '1em', position: 'relative'
                            }}>
                                <div style={{ width: '15%' }}>
                                    {
                                        theme
                                            ? !themebg ? <MdOutlineWbSunny size={15} /> : <BsFillBrightnessHighFill size={15} />
                                            : !themebg ? <IoMoonOutline size={15} /> : <BsFillMoonFill size={15} />
                                    }

                                </div>
                                <div style={{ width: '70%', fontStyle: 'italic' }}>
                                    {
                                        theme
                                            ? <p>{t('Header.Menu.Theme.light')}</p>
                                            : <p>{t('Header.Menu.Theme.dark')}</p>
                                    }
                                </div>
                                <div style={{ width: '20%' }}>
                                    <Switch
                                        checked={theme}
                                        onChange={() => changeTheme(!theme)}
                                        size="small"
                                        style={{ width: '50%', backgroundColor: theme ? theme ? '#a8c1f4' : '#d5d5d5' : '', color: '#000' }}
                                    // style={{ width: '50%' }}
                                    />
                                </div>
                                <Button
                                    type="ghost" onClick={() => setOnDevice(!onDevice)}
                                    style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        position: 'absolute', right: '0px', top: '11px'
                                    }}
                                    icon={onDevice ? <MdKeyboardArrowUp size={15} style={{
                                        color: theme ? '' : '#AAA'
                                    }} /> : <MdKeyboardArrowDown size={15} style={{
                                        color: theme ? '' : '#AAA'
                                    }} />} />
                            </div>
                            {/* <hr/> */}
                        </Menu.Item>

                        <Menu.Item
                            onMouseEnter={() => setDevicebg(true)} onMouseLeave={() => setDevicebg(false)}
                            style={{
                                display: onDevice ? '' : 'none',
                                color: `${theme ? '' : (devicebg ? '#e3e3e3' : '#e3e3e3')}`, margin: '0.5vh 0 0.5vh 0', height: 50, cursor: 'default',
                                backgroundColor: `${theme ? (devicebg ? '#a8c1f480' : '#fff') : (devicebg ? '#a8c1f460' : '#333437')}`,
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', paddingLeft: '1em', paddingRight: '1em' }}>
                                <div style={{ width: '15%' }}>
                                    {
                                        !devicebg ? <IoSettingsOutline size={15} /> : <IoSettingsSharp size={15} />
                                    }

                                </div>
                                <div style={{ width: '70%', fontStyle: 'italic' }}>
                                    <p>{t('deviceSettings')}</p>
                                    {/* {selectDevice ? 'true' : 'false'} */}
                                </div>
                                <div style={{ width: '20%' }}>
                                    <Switch
                                        checked={selectDevice}
                                        onChange={() => setselectDevice(!selectDevice)}
                                        size="small"
                                        style={{ width: '50%', backgroundColor: selectDevice ? theme ? '#a8c1f4' : '#d5d5d5' : '', color: '#000' }}
                                    // style={{ width: '50%' }}
                                    />
                                </div>

                            </div>
                            {/* <hr/> */}
                        </Menu.Item>

                        <Menu.Item
                            onMouseEnter={() => setLogoutbg(true)} onMouseLeave={() => setLogoutbg(false)} onClick={logout}
                            style={{
                                color: `${theme ? '' : (logoutbg ? '#333' : '#e3e3e3')}`, height: 50, cursor: 'pointer',
                                borderRadius: '0 0 3vh 3vh',
                                backgroundColor: `${theme ? (logoutbg ? '#F9AFCD80' : '#fff') : (logoutbg ? '#946D7F' : '#333437')}`,
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', paddingLeft: '1em', paddingRight: '1em' }}>
                                <div style={{ width: '15%' }}>
                                    <FiLogOut size={15} style={{ color: `${theme ? '#000' : ''}`, cursor: 'pointer', }} />
                                </div>
                                <div style={{ width: '60%' }}>
                                    <p style={{ color: `${theme ? '#000' : ''}`, cursor: 'pointer' }}>{t('Header.Menu.logout')}</p>
                                </div>
                            </div>


                        </Menu.Item>
                    </Menu>
                    : <></>
            }
        </>

    )

    const handleLogo = () => {
        setTimeout(() => {
            setOnTitle(!onTitle)
        }, 0);
    }

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    };

    // const [theme, setTheme] = useState(false); // Inicialmente, usa el tema oscuro

    useEffect(() => {

        if (selectDevice) {
            const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
            handleTheme(!darkModeMediaQuery.matches)
            setListener(true)
            
        } else {
            setListener(false)
        }

        handleDevice(selectDevice)
    }, [selectDevice]);

    useEffect(() => {

        if (listener) {
            const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
            const handleThemeChange = (event) => {
                handleTheme(!event.matches)
            };

            darkModeMediaQuery.addEventListener('change', handleThemeChange)
            handleTheme(!darkModeMediaQuery.matches)

            return () => {
                darkModeMediaQuery.removeEventListener('change', handleThemeChange)
            }
        }
    }, [listener]);

    useEffect(() => {
      handleDevice(selectDevice)
    }, [])
    

    return (
        <Header
            className="site-layout-background"
            style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection: 'row',
                position: 'fixed', zIndex: 1, width: '100%',
                height: '11vh',
                backdropFilter: 'blur(18px)',
                transition: 'all 0.55s ease-in-out', backgroundColor: `${(theme) ? '#a8c1f490' : '#25262880'}`,
                boxShadow: theme ? '' : '0px 7px 5px #00000020',
                // borderBottom: theme ? '2px solid #68789A60' : '2px solid #000',
            }}>

            <div style={{
                width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                flexDirection: 'row'
            }}>

                <div
                    className="logo-header"
                    style={{
                        color: `${(theme) ? '#000' : '#e3e3e3'}`, fontSize: '1.4em',
                        marginLeft: '-3vw', flexDirection: 'row', cursor: 'default',
                        alignItems: 'center', justifyContent: 'left', width: '50%',
                        height: '100%', transition: 'all 0.65s ease-in-out'
                    }}>

                    <Avatar
                        onClick={handleLogo}
                        className={onAvatar ? 'icon' : 'avatar_'}
                        shape="circle"
                        style={{ transition: 'all 0.55s ease-in-out', position: 'absolute', cursor: 'pointer' }}
                        size={45}
                        src={theme ? logos.logo : logos.darklogo}
                        onMouseEnter={() => setOnAvatar(!onAvatar)}
                        icon={<UserOutlined />}
                    />
                    <div
                        // className={onTitle ? 'box-title-small' : 'box-title-normal'}
                        // onMouseEnter={() => setOnTitle(!onTitle)}
                        style={{
                            height: '3.5vh', marginLeft: '1vh',
                            backgroundColor: `${theme ? '#fff' : '#393b3f'}`,
                            borderRadius: '2vh',
                            // transition: 'all 3.85s ease-in-out',
                            display: 'flex', alignItems: 'center', justifyContent: 'center',

                        }}>
                        {
                            !onTitle ? <p style={{
                                fontSize: '1em', paddingLeft: '6vh', paddingRight: '2vh', lineHeight: '0em',
                                // transition: 'all 0.45s ease-in-out'
                            }}>{props.text}</p>
                                : <p style={{
                                    fontSize: '1em', paddingLeft: '6vh', paddingRight: '2vh', lineHeight: '0em',
                                    // transition: 'all 0.45s ease-in-out'
                                }}><b>{t('Header.Hi')}</b>, {user.name.split(' ').slice(0, 2).join(' ')}</p>
                        }

                    </div>

                </div>

                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                }}>
                    {
                        props.module === 'home'
                            ?
                            <Button
                                onMouseEnter={() => setOnHome(true)} onMouseLeave={() => setOnHome(false)}
                                className="div-searcher"
                                // onClick={() => navigate("/home-page", { replace: true })}
                                icon={<BsFillHouseFill size={20} style={{ color: theme ? '#333' : '#AAA' }} />}
                                style={{
                                    margin: '0 5px 0 0', transition: 'all 0.65s ease-in-out',
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                    borderRadius: '2vh', backgroundColor: `${theme ? '#fff' : '#393b3f'}`,
                                    border: '0px solid #000'
                                }}>
                                <p style={{
                                    fontWeight: 'bolder', marginLeft: '5px', color: theme ? '#333' : '#AAA'
                                }}>{t('Home.title')}</p>
                            </Button>
                            :
                            <Button
                                onMouseEnter={() => setOnHome(true)} onMouseLeave={() => setOnHome(false)}

                                className="div-searcher"
                                onClick={() => navigate("/home-page", {
                                    replace: true
                                })}
                                icon={
                                    onHome ? <BsFillHouseFill size={20} style={{ color: theme ? '#333' : '#aaaaaa' }} />
                                        : <BsHouse size={20} style={{ color: theme ? '#333' : '#aaaaaa80' }} />}
                                type="ghost" style={{ margin: '0 5px 0 0', transition: 'all 0.45s ease-in-out' }} />
                    }


                    {
                        props.module === 'shipments'
                            ?
                            <Button
                                onMouseEnter={() => setOnShipment(true)} onMouseLeave={() => setOnShipment(false)}
                                className="div-searcher"
                                // onClick={() => navigate("/shipments", { replace: true })}
                                icon={<BsEnvelopeFill size={20} style={{ color: theme ? '#333' : '#aaaaaa' }} />}
                                style={{
                                    margin: '0 5px 0 0', transition: 'all 0.65s ease-in-out',
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                    borderRadius: '2vh', backgroundColor: `${theme ? '#fff' : '#393b3f'}`,
                                    border: '0px solid #000'
                                }}>
                                <p style={{
                                    fontWeight: 600, marginLeft: '5px', color: theme ? '#333' : '#AAA'
                                }}>{t('Ship.title')}</p>
                            </Button>
                            :
                            <Button
                                onMouseEnter={() => setOnShipment(true)} onMouseLeave={() => setOnShipment(false)}

                                className="div-searcher"
                                onClick={() => navigate("/shipments", {
                                    replace: true
                                })}
                                icon={
                                    onShipment ? <BsEnvelopeFill size={20} style={{ color: theme ? '#333' : '#aaaaaa' }} />
                                        : <BsEnvelope size={20} style={{ color: theme ? '#333' : '#aaaaaa80' }} />}
                                type="ghost" style={{ margin: '0 5px 0 0', transition: 'all 0.45s ease-in-out' }} />
                    }

                    {
                        props.module === 'reviews'
                            ?
                            <Button
                                onMouseEnter={() => setOnReviews(true)} onMouseLeave={() => setOnReviews(false)}
                                className="div-searcher"
                                // onClick={() => navigate("/reviews", { replace: true })}
                                icon={<BsClipboardCheckFill size={20} style={{ color: theme ? '#333' : '#aaaaaa' }} />}
                                style={{
                                    margin: '0 5px 0 0', transition: 'all 0.65s ease-in-out',
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                    borderRadius: '2vh', backgroundColor: `${theme ? '#fff' : '#393b3f'}`,
                                    border: '0px solid #000'
                                }}>
                                <p style={{
                                    fontWeight: 600, marginLeft: '5px', color: theme ? '#333' : '#AAA'
                                }}>{t('Reviews.Title')}</p>
                            </Button>
                            :
                            <Button
                                onMouseEnter={() => setOnReviews(true)} onMouseLeave={() => setOnReviews(false)}

                                className="div-searcher"
                                onClick={() => navigate("/reviews", {
                                    replace: true
                                })}
                                icon={
                                    onReviews ? <BsClipboardCheckFill size={20} style={{ color: theme ? '#333' : '#aaaaaa' }} />
                                        : <BsClipboardCheck size={20} style={{ color: theme ? '#333' : '#aaaaaa80' }} />}
                                type="ghost" style={{ margin: '0 5px 0 0', transition: 'all 0.45s ease-in-out' }} />
                    }

                    <Dropdown overlay={menu} onVisibleChange={handleVisibleChange} visible={visible}>
                        <Button
                            // type={visible ? '' : 'ghost'}
                            style={{
                                transition: 'all 0.45s ease-in-out', borderRadius: '3vh',
                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                backgroundColor: visible ? theme ? '' : '#393b3f' : 'transparent',
                                border: '0px solid #000',
                            }}
                            // className="div-searcher"
                            icon={
                                visible
                                    ? <FaUser size={16} style={{ color: theme ? '#333' : '#aaa' }} />
                                    : <FaRegUser size={16} style={{ color: theme ? '#333' : '#aaaaaa80' }} />}
                        />
                    </Dropdown>
                </Row>
            </div>

        </Header>
    )
}
