import { Button, Checkbox, Image, Layout, Row, Switch } from 'antd'
import React, { useContext, useState } from 'react'
import { HeaderSpecific } from '../Layouts/shipments/headerSpecific'
import { ContentSpecific } from '../Layouts/shipments/ContentSpecific'
import {  appContext } from '../context/AuthContext'


export const SpecificCard = (props) => {
    const { theme} = useContext(appContext)
    const { data, action, setSpecificDocs, trainingDocById, resetIDE, curentPage, setCurentPage, sendData, eneable, tests} = props

    
    return (

        <Layout style={{  width:'100%', backgroundColor:  `${theme ? '#fefefe' : '#2B2D31'}`}} >
            <HeaderSpecific data={data} trainingDocById={trainingDocById} resetIDE={resetIDE} sendData={sendData} eneable={eneable} tests={tests}/>
            <ContentSpecific data={data} setSpecificData={action} setSpecificDocs={setSpecificDocs} curentPage={curentPage} setCurentPage={setCurentPage}/>
        </Layout>


        
    )
}
