import { Row, Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import { BiErrorAlt } from "react-icons/bi";
import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from "react-icons/io5";

export const formatDocument = (document) => {
    switch (document) {
        case 'NI':
            return 'national-id'
        case 'DL':
            return 'drivers-license'
        case 'PS':
            return 'passport'

        default:
            break;
    }

}


export const handleReasons = (status, reason, t) => {
    switch (status) {
        case 'SENT': return `${t('Reasons.Sent')}`
        case 'READY': return `${t('Reasons.Ready')}`
        case 'REVIEWED': return `${t('Reasons.Reviewed')}`
        case 'ERROR': return reason
        default:
            break;
    }
}

export const handleStatus = (status, reason) => {
    switch (status) {
        case 'SENT': return "Your test has just been submitted, please wait for it to be ready for validation."
        case 'READY': return "Your test is ready to be validated."
        case 'REVIEWED': return "Your test has been reviewed."
        case 'ERROR': return `ERROR - ${reason}`
        default:
            break;
    }
}

export const handleStatusName = (status, t) => {
    switch (status) {
        case 'SENT': return `${t('State.Sent.Name')}`
        case 'READY': return `${t('State.Ready.Name')}`
        case 'REVIEWED': return `${t('State.Reviewed.Name')}`
        case 'ERROR': return `${t('State.Error.Name')}`
        default:
            break;
    }
}

export const handleColors = (status) => {
    switch (status) {
        case 'SENT': return "#5abf86"
        case 'READY': return "#f0c145"
        case 'REVIEWED': return "#5abf86"
        case 'ERROR': return "#ff4f5b"
        default:
            break;
    }
}

export const formatDate = (date) => {

    if (date) {
        const fechaOriginal = new Date(date);
        fechaOriginal.setHours(fechaOriginal.getHours() + 6);
        const opcionesFecha = { month: '2-digit', day: '2-digit', year: 'numeric' };
        // const opcionesHora = { hour: '2-digit', minute: '2-digit' };
        const fechaFormateada = `${fechaOriginal.toLocaleDateString(undefined, opcionesFecha)}`
        return fechaFormateada
    }

    else return 'Pending'

}

export const formatTime = (date) => {

    const fechaOriginal = new Date(date);
    fechaOriginal.setHours(fechaOriginal.getHours() + 6);
    // const opcionesFecha = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const opcionesHora = { hour: '2-digit', minute: '2-digit' };
    const fechaFormateada = `${fechaOriginal.toLocaleTimeString(undefined, opcionesHora)}`;
    return fechaFormateada

}

export const DateForReview = (date) => {

    const fecha = new Date(date)
    const expectedDate = fecha.setHours(fecha.getHours() + 3)
    return formatDate(expectedDate)
}

export const TimeForReview = (date) => {

    const fecha = new Date(date)
    const expectedDate = fecha.setHours(fecha.getHours() + 3)
    return formatTime(expectedDate)
}

export const handleHeaders = (state, theme, t,) => {
    switch (state) {
        case 'SENT':
            return (
                <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <Spin indicator={<LoadingOutlined style={{ color: theme ? handleColors(state) : `${handleColors(state)}90` }} spin />} />
                    {/* <AiOutlineSend size={25} style={{ color: handleColors(state) }} /> */}
                    <b style={{
                        width: '85%', textAlign: 'left', fontSize: '1.5em', marginLeft: '10px',
                        color: theme ? handleColors(state) : `${handleColors(state)}90`
                    }}>{t('Reviews.head.sent')}</b>
                </Row>
            )

        case 'READY':
            return (
                <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <IoCheckmarkSharp size={25} style={{ color: theme ? handleColors(state) : `${handleColors(state)}90` }} />
                    <b style={{
                        width: '85%', textAlign: 'left', fontSize: '1.5em', marginLeft: '10px',
                        color: theme ? handleColors(state) : `${handleColors(state)}90`
                    }}>{t('Reviews.head.ready')}</b>
                </Row>
            )

        case 'ERROR':
            return (
                <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <BiErrorAlt size={25} style={{ color: theme ? handleColors(state) : `${handleColors(state)}90` }} />
                    <b style={{
                        width: '85%', textAlign: 'left', fontSize: '1.5em', marginLeft: '10px',
                        color: theme ? handleColors(state) : `${handleColors(state)}90`
                    }}>{t('Reviews.head.error')} </b>
                </Row>
            )


        case 'REVIEWED':
            return (
                <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <IoCheckmarkDoneSharp size={25} style={{ color: theme ? handleColors(state) : `${handleColors(state)}90` }} />
                    <b style={{
                        width: '85%', textAlign: 'left', fontSize: '1.5em', marginLeft: '10px',
                        color: theme ? handleColors(state) : `${handleColors(state)}90`
                    }}>{t('Reviews.head.reviewed')}</b>
                </Row>
            )

        default:
            break;
    }
}

export const handleComments = (state, data, theme, t, date, finalDate) => {
    let label_date = null
    if (date === finalDate) label_date = true
    else label_date = false
    switch (state) {
        case 'SENT':
            return (
                <b
                    // onClick={addData}
                    style={{
                        textAlign: 'left', fontWeight: 400, fontSize: '1.1em',
                        color: theme ? '' : 'rgba(170,170,170,0.9)'
                    }}>{t('Reviews.comment.sent.prefix')}<b>{data.length}</b>{t('Reviews.comment.sent.sufix')}</b>
            )

        case 'READY':
            return (
                <b
                    // onClick={addData}
                    style={{
                        textAlign: 'left', fontWeight: 400, fontSize: '1.1em',
                        color: theme ? '' : 'rgba(170,170,170,0.9)'
                    }}>{t('Reviews.comment.ready.prefix')}<b>{data.length}</b>{t('Reviews.comment.ready.sufix')}</b>
            )

        case 'ERROR':
            return (


                label_date ?
                    <b
                        style={{
                            textAlign: 'left', fontWeight: 400, fontSize: '1.1em',
                            color: theme ? '' : 'rgba(170,170,170,0.9)'
                        }}>{t('Reviews.comment.ready.prefix')}<b>{data.length}</b>{t('Reviews.comment.error.sufix')} {t('From')} <b>{date}</b></b>
                    :
                    <b
                        style={{
                            textAlign: 'left', fontWeight: 400, fontSize: '1.1em',
                            color: theme ? '' : 'rgba(170,170,170,0.9)'
                        }}>{t('Reviews.comment.ready.prefix')}<b>{data.length}</b>{t('Reviews.comment.error.sufix')} {t('From')} <b>{date}</b> {t('to')} <b>{finalDate}</b>
                    </b>


            )


        case 'REVIEWED':
            return (

                label_date ?
                    <b
                        // onClick={addData}
                        style={{
                            textAlign: 'left', fontWeight: 400, fontSize: '1.1em',
                            color: theme ? '' : 'rgba(170,170,170,0.9)'
                        }}>{t('Reviews.comment.reviewed.prefix')}<b>{data.length}</b>{t('Reviews.comment.reviewed.sufix')} {t('From')} <b>{date}</b></b>

                    : <b
                        style={{
                            textAlign: 'left', fontWeight: 400, fontSize: '1.1em',
                            color: theme ? '' : 'rgba(170,170,170,0.9)'
                        }}>{t('Reviews.comment.reviewed.prefix')}<b>{data.length}</b>{t('Reviews.comment.reviewed.sufix')} {t('From')} <b>{date}</b> {t('to')} <b>{finalDate}</b>
                    </b>
            )

        default:
            break;
    }
}