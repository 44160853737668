import { Layout } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { FooterApp } from '../Layouts/home/FooterApp'
import { HeaderApp } from '../Layouts/home/HeaderApp'
import { t } from 'i18next'
import { appContext } from '../context/AuthContext'
import { SiderReviews } from '../Layouts/reviews/SiderReviews'
import { ContentReviews } from '../Layouts/reviews/ContentReviews'
import useAxios from '../Hooks/UseAxios'
import { renew_jwt } from '../helpers/renew_jwt'



export const ReviewsPage = () => {

    const [collapsed, setCollapsed] = useState(true)
    const [search, setSearch] = useState(true)
    const [viewID, setViewID] = useState('')

    const { theme, logout } = useContext(appContext)
    const { response, loading, error, operation } = useAxios()

    useEffect(() => {
        renew_jwt(logout, operation)
    }, [])

    useEffect(() => {
        const changeBody = () => {
            // document.body.style.backgroundColor = `${theme ? '#fff' : '#2b2d31'}`;
            document.body.style.background = `linear-gradient(to bottom, ${theme ? '#FFF' : '#2b2d31'} , ${theme ? '#FAFAFA' : '#2b2d31'} )`
        }
        changeBody()
    }, [theme])

    useEffect(() => {
        if (response) {
            console.log(response.data.data.token)
            localStorage.setItem("token", response.data.data.token)
        }
    }, [response])

    return (

        <Layout style={{ backgroundColor: theme ? '#ffffff80' : 'transparent' }}>
            <HeaderApp text={<p><b>Meta</b>Map</p>} module={'reviews'} />
            {/* <HeaderApp text={<p>{t('Reviews.Title')}</p>} module={'reviews'} /> */}
            <Layout style={{ backgroundColor: theme ? '#ffffff80' : 'transparent' }} >
                <ContentReviews collapsed={collapsed} setCollapsed={setCollapsed} setSearch={setSearch} search={search} setViewID={setViewID} />
                <SiderReviews collapsed={collapsed} setCollapsed={setCollapsed} search={search} setSearch={setSearch} viewID={viewID} />
            </Layout>
            <FooterApp />
        </Layout>


    )
}
