import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QATapp } from './QATapp'
import './styles'

ReactDOM.createRoot(document.getElementById('root')).render(
  
  <BrowserRouter >
        <QATapp />
  </BrowserRouter>
  
  
)

// If you want to start measuring performance in your app, pass a function
