import { Layout } from "antd"
import { useContext } from "react"
import { appContext } from "../../context/AuthContext"

const { Footer } = Layout

export const FooterApp = () => {

  // const { theme } = useContext( AuthContext )
  const { theme} = useContext(appContext)
  return (
    <Footer style={{ textAlign: 'center', 
    // borderTop: '2px solid #000', borderBottom: '2px solid #000',
          backgroundColor: `${(theme)? '#FAFAFA': '#2b2d31'}`, 
        //   borderBottomRightRadius:'1vw', borderBottomLeftRadius: '1vw',
          color:`${(theme)? '#00000070': '#aaa'}`, transition: 'all 0.55s ease-in-out',}}>
       QAT: Send and Review ©2023 Created by MAS DevOps
    </Footer>

  )
}
