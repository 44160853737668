
export const postUser = async (operation, user, pass) => {
    try {
        await operation({
            method: "POST",
            url: "/auth/login",
            headers: { accept: "*/*" },
            data: {
                Email: user,
                Password: pass,
            },
        })

    }
    catch {
    }

}

export const authUser = async (operation, token) => {
    try {
        await operation({
            method: "GET",
            url: "/auth/user/info",
            headers: { token: `${token}` }
        })
    } catch (error) { }
}


export async function getAmountPerDifficulty(operation, typeShipment, country, typeDocument, DocCountry, typeTest, state, difficulties) {

    try {
        await operation({
            method: "POST",
            url: "/TrainingDocs/TrainigDocsFilter",
            headers: { accept: "*/*" },
            data: {
                Type_Of_Shipment: typeShipment,
                Country: country,
                Difficulty: difficulties,
                Type_Document: typeDocument,
                Doc_By_Country: DocCountry,
                Type_Docs_Test: typeTest,
                State: state,
                Delay: ""
            }
        })
    } catch (error) {
        console.error(error)
    }

}

export async function getDifficulties(operation) {
    try {
        await operation({
            method: "GET",
            url: "/CatDifficulties/",
        })

    } catch (error) {
        console.error(error)
    }

}

export async function getCountries(operation) {
    try {
        await operation({
            method: "GET",
            url: "/CatCountry/",
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getDocuments(operation) {
    try {
        await operation({
            method: "GET",
            url: "/CatDocument/",
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getDocsByCountry(operation) {
    try {
        await operation({
            method: "GET",
            url: "/DocByCountry/",
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getStates(operation) {
    try {
        await operation({
            method: "GET",
            url: "/CatState/",
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getDocTests(operation) {
    try {
        await operation({
            method: "GET",
            url: "/CatDocTest/",
        })

    } catch (error) {
        console.error(error)
    }

}

export async function getAllErrors(operation) {
    try {
        await operation({
            method: "GET",
            url: "/CatErrors/",
        })

    } catch (error) {
        console.error(error)
    }
}



export const postData = async (operation, typeShipment, country, document, docCountry, difficulties, typeTest, state) => {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/CreateShipment/CreateShipment",
            headers: {
                accept: '*/*',
                token: `${token}`
            },
            data: {
                Type_Of_Shipment: typeShipment.toUpperCase(),
                Country: country.toUpperCase(),
                Type_Document: document.toUpperCase(),
                Doc_By_Country: docCountry,
                Doc_Difficulties: difficulties,
                Type_Docs_Test: typeTest.toUpperCase(),
                State: state
            },
        })

    }
    catch {


    }

}

export async function getTrainingDocsById(operation, _id) {

    try {
        await operation({
            method: "POST",
            url: "/TrainingDocs/TrainigDocsById",
            headers: { accept: "*/*" },
            data: {
                _id: [_id]
            }
        })
    } catch (error) {
        console.error(error)
    }

}

/*** lates shipments */


export async function getData(operation, date, finalDate) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/LatestShipments/LatestShipments",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: date,
                fFinal: finalDate,
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function getAllData(operation, date, finalDate) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/LatestShipments/AllLatestShipments",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: date,
                fFinal: finalDate,
            },
        })

    } catch (error) {
        console.error(error)
    }

}


export async function getReport(operation, ReportDate) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/DailyReport",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: ReportDate,
                fFinal: ReportDate,
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function getTest(operation, id) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/LatestDetails/specificDetail",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                identityID: id,
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function myTestByState(operation, state) {
    const token = localStorage.getItem("token");
    try {
        // message.success('hola')
        await operation({
            method: "POST",
            url: "/TestsByState/MyTests",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                Status: state,
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function MyRevieweds(operation, date, finalDate) {
    const token = localStorage.getItem("token");
    try {
        // message.success('hola')
        await operation({
            method: "POST",
            url: "/TestsByState/MyRevieweds",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: date,
                fFinal: finalDate
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function MyErrors(operation, date, finalDate) {
    const token = localStorage.getItem("token");
    try {
        // message.success('hola')
        await operation({
            method: "POST",
            url: "/TestsByState/MyErrors",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: date,
                fFinal: finalDate
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function allTestByState(operation, state) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/TestsByState/AllTest",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                Status: state,
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function AllRevieweds(operation, date, finalDate) {
    const token = localStorage.getItem("token");
    try {
        // message.success('hola')
        await operation({
            method: "POST",
            url: "/TestsByState/AllRevieweds",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: date,
                fFinal: finalDate
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function AllErrors(operation, date, finalDate) {
    const token = localStorage.getItem("token");
    try {
        // message.success('hola')
        await operation({
            method: "POST",
            url: "/TestsByState/AllErrors",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                fInicial: date,
                fFinal: finalDate
            },
        })

    } catch (error) {
        console.error(error)
    }

}

/* Reviews */

export async function getTestForReview(operation, id) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/Reviews/GetTest",
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                IdentityID: id,
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function editAndSaveTest(operation, newData, identityID) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "PUT",
            url: `/Reviews/SaveTest/${identityID}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                Status: newData.Status,
                AD_Comment: newData.AD_Comment,
                TM_Comment: newData.TM_Comment,
                DR_Comment: newData.DR_Comment,
                AD_Answer: newData.AD_answer,
                TM_Answer: newData.TM_answer,
                DR_Answer: newData.DR_answer,
                AD_Overtime: newData.AD_Overtime,
                TM_Overtime: newData.TM_Overtime,
                DR_Overtime: newData.DR_Overtime,
                AD_Type_Of_Error: newData.AD_Type_Of_Error,
                TM_Type_Of_Error: newData.TM_Type_Of_Error,
                DR_Type_Of_Error: newData.DR_Type_Of_Error,
                DR_Agent_Answer: newData.DR_Agent_Answer,
                DR_If_Typo: newData.DR_If_Typo
            },
        })

    } catch (error) {
        console.error(error)
    }

}

export async function reportDocument(operation, values, DocumentID) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: `/ReportDocuments/`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                Document_Id: DocumentID,
                Field: values.Field,
                Type: values.Type,
                Comment: values.Comment,
                Active: true,
            },
        })

    } catch (error) {
        console.error(error)
    }

}