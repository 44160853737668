

import { Button, Checkbox, Form, Input, Avatar, Layout, message, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAxios from '../Hooks/UseAxios'
import { t } from 'i18next';
import { appContext } from '../context/AuthContext';
import { images, logos } from '../helpers/logo'
import { authUser, postUser } from '../services/apiServices';

const { Content } = Layout

export const LoginPage = () => {
    const navigate = useNavigate()
    const { login, handleTheme } = useContext(appContext)

    // const [accessToken, setAccessToken] = useState('')
    const [token, setToken] = useState(null)
    const [username, setUsername] = useState('')
    const [visible, setVisible] = useState(false)
    const [loader, setLoader] = useState(false)
    const { response, loading, error, operation } = useAxios()

    function tokenExpires(date) {
        // Clonar la fecha original para evitar modificaciones no deseadas
        const newDate = new Date(date);

        // Sumar 7 horas y 30 minutos a la nueva fecha
        newDate.setHours(newDate.getHours() + 7);
        newDate.setMinutes(newDate.getMinutes() + 30);

        // const formatDate = newDate.toISOString();

        return newDate;
    }


    const onFinish = (values) => {
        postUser(operation, values.username, values.password)
        setUsername(values.username)
    }

    useEffect(() => {
        if (token) {
            authUser(operation, token)
            localStorage.setItem("token", token)
            const now = new Date()
            localStorage.setItem("expires", tokenExpires(now))
            console.log(tokenExpires(now))
            setLoader(true)

        }
    }, [token])


    useEffect(() => {
        if (!loading) {
            switch (response.data.msg) {
                case 'Valid user':
                    const token = response.data.data.Token
                    console.log(token)
                    setToken(token)

                    break;

                case 'User Info':
                    const user = response.data.data
                    if (user) {
                        console.log(user.uid)
                        const newUser = {
                            username: username,
                            dayOff: user.Day_Off,
                            hub: user.Hub,
                            name: user.Name,
                            profilePicture: user.Profile_Picture,
                            role: user.Role,
                            team: user.Team,
                            _id: user.uid
                        }
                        login(newUser)
                        navigate(`/home-page`, {
                            replace: true
                        })
                        handleTheme(true)
                        setLoader(false)
                    }

                    break

                case 'User not found':
                    const msg = response.data.msg
                    message.error(msg)
                    // if (response.data.msg === 'User not found') {
                    //     console.log(response.data.msg)
                    //     message.error('User not found')
                    // }
                    break

                default:
                    break;
            }

        }
    }, [response])


    useEffect(() => {
        const changeBody = () => {
            document.body.style.backgroundColor = `#fff0e7`;
        }
        changeBody()
    }, [])


    return (
        <>

            <Layout>

                {
                    !loader
                        ?
                        <Content
                            className='background-app login-normal'
                            style={{
                                height: '98vh', alignItems: 'center',
                                justifyContent: 'center', backgroundColor: '#fff0e7', width: 'auto',
                                // display:'none'
                            }}>
                            <div
                                className='div-searcher'
                                style={{
                                    height: '100%', width: 'auto',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
                                    flexWrap: 'wrap', transition: 'transform 0.45s ease-out'
                                }}>
                                <div
                                    className='login-fff-side'
                                    // className='div-searcher'
                                    style={{
                                        width: '60vh', height: '50%', backgroundColor: '#fefefe',
                                        border: '0px solid #000',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                                        // flexWrap: 'wrap'
                                    }}>

                                    <div style={{
                                        height: '20%', width: '100%',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                                    }}>
                                        <Row style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                        }}>
                                            <img shape='square' src={images.metamap} style={{
                                                height: '3vh', marginRight: '1vh'
                                            }} />
                                            <p style={{
                                                fontSize: '1.4em', color:'#333',
                                            }}>Meta<b>Map</b></p>
                                        </Row>

                                        <p
                                            style={{
                                                // fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                                                fontSize: '2em', marginTop: '-1vh',
                                                fontWeight: 'bold', color:'#333'
                                            }}>{t('Login.welcome')}</p>

                                    </div>

                                    <div
                                        style={{
                                            height: '60%', width: '100%', display: 'flex',
                                            alignItems: 'center', justifyContent: 'center',
                                        }}>

                                        <Form
                                            name="basic"
                                            onFinish={onFinish}
                                            style={{
                                                height: '100%', width: '100%', marginTop:'40px',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                                            }}
                                        >

                                            <Form.Item
                                                name="username"
                                                style={{ display: 'flex', alignItems:'center', justifyContent: 'center', marginBottom: '-0.1em' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your username!',
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Username'
                                                    style={{ width: '20vw', border: '', borderRadius: '3vh' }} />
                                            </Form.Item>
                                            <Form.Item

                                                name="password"
                                                style={{ display: 'flex', alignItems:'center', justifyContent: 'center', marginBottom: '1em' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    placeholder='Password'
                                                    style={{
                                                        width: '20vw', border: '', borderRadius: '3vh',
                                                        marginTop:'2vh'
                                                    }} />
                                            </Form.Item>

                                            <Form.Item
                                                name="remember"
                                                valuePropName="checked"
                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', }}

                                            >

                                                <Checkbox style={{ width: '20vw', borderRadius:'3vh' }}>{t('Login.remember')}</Checkbox>
                                            </Form.Item>

                                            <Form.Item
                                                style={{
                                                    display: 'flex', justifyContent: 'center', marginBottom: '1em',
                                                    marginTop: '1vh'
                                                }}

                                            >
                                                <Button className='prev'htmlType="submit"
                                                    style={{
                                                        width: '20vh', borderRadius:'3vh', backgroundColor:'#fdb88c',
                                                        border:'0px solid #000', transition: 'transform 0.35s ease-out',
                                                        color:'#333', fontWeight:'bolder'
                                                    }} >
                                                    {t('Login.login')}
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                                <div
                                    className='login-color-side'
                                    style={{
                                        width: '40vh', height: '50%', backgroundColor: '#fdb88c80',
                                        border: '0px solid #000', marginLeft: '1vh', borderRadius: '0 2vw 2vw 0', display: 'flex',
                                        alignItems: 'center', justifyContent: 'center', position: 'relative',
                                    }}>
                                    <img
                                        src={images.login_logo}
                                        style={{
                                            height: '110%', position: 'absolute'
                                        }} />

                                </div>

                            </div>
                        </Content>
                        :

                        <>
                            <Content
                                className='background-app'
                                style={{
                                    height: '98vh', alignItems: 'center',
                                    justifyContent: 'center', backgroundColor: '#fff0e7', width: 'auto',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                                    // display:'none'
                                }}>
                                <img src={logos.rainbow} style={{ marginTop: '-20vh', width: '8%', margin: 0 }} />
                            </Content>


                        </>
                }



                <div
                    className='login-small'
                    style={{
                        alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                        backgroundColor: '#FBFBFB', height: '100vh', width: '100%',
                        // padding:'8%'
                    }}>

                    <img src={images.error} style={{ margin: 0 }} />
                    <p style={{
                        width: '80%', textAlign: 'center', fontWeight: 600, fontSize: '2em',
                        margin: 0
                    }}>This application is not available for mobile devices. Please use a computer or tablet.</p>
                    {/* <div style={{
                        height: '20%', width: '100%',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                    }}>
                        <Row style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <img shape='square' src={images.metamap} style={{
                                height: '3vh', marginRight: '1vh'
                            }} />
                            <p style={{
                                fontSize: '1.4em',
                                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                            }}>Meta<b>Map</b></p>
                        </Row>

                        <h1
                            style={{
                                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                                fontSize: '2rem', marginTop: '-1vh'
                            }}>{t('Login.welcome')}</h1>

                    </div>

                    <div

                        style={{
                            height: '70%', width: '60%', display: 'flex',
                            alignItems: 'center', justifyContent: 'center',
                        }}>

                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            style={{
                                height: '100%', width: '100%',
                                // marginLeft: '-1em',
                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                            }}

                        >
                            <div style={{ display: 'flex', width: '100%', marginBottom: '-1em' }}>
                                <p style={{
                                    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                                }}>{t('Login.username')}<b style={{ color: 'red' }}> *</b></p>
                            </div>

                            <Form.Item
                                // label="Username"
                                name="username"
                                style={{
                                    display: 'flex', justifyContent: 'flex-start', marginBottom: '-0.1em',
                                    width: '100%',
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    }
                                ]}
                            >
                                <Input style={{ width: '35vh', marginBottom: '-2em', border: '1.2px solid black' }} />
                            </Form.Item>

                            <div style={{ display: 'flex', justifyContent: 'left', width: '18vw', marginBottom: '-1em' }}>
                                <p style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", }}>{t('Login.password')}<b style={{ color: 'red' }}> *</b></p>
                            </div>
                            <Form.Item

                                name="password"
                                style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password style={{ width: '35vh', marginBottom: '-2em', border: '1.2px solid black' }} />
                            </Form.Item>

                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '1em', width: '30vh', }}

                            >

                                <Checkbox style={{ width: '30vh', marginBottom: '-2em', fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", }}>{t('Login.remember')}</Checkbox>
                            </Form.Item>

                            <Form.Item
                                style={{
                                    display: 'flex', justifyContent: 'center', marginBottom: '1em',
                                    marginTop: '2vh', width: '100%'
                                }}

                            >
                                <Button className='login-button' type="primary" htmlType="submit"
                                    style={{
                                        width: '20vh', fontSize: '1.2em', lineHeight: '0em',
                                        fontWeight: 500, fontFamily: "Tahoma, Geneva, Verdana, sans-serif",
                                    }} >
                                    {t('Login.login')}
                                </Button>
                            </Form.Item>
                        </Form>


                    </div> */}
                </div>

            </Layout>



            {/* <Modal
                    // title = 'Press "OK" if you want to cancel the operation'

                    style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                    open={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    modalStyle={{ backgroundColor: 'blue', color: 'white' }}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}

                    footer={
                        <div style={{ textAlign: 'center' }}>

                        </div>
                    }>
                    <div className="user-not-found" style={{ height: '60vh', width: '70vh' }}>

                    </div>
                </Modal> */}

        </>
    )


}

