import { type } from '@testing-library/user-event/dist/type'
import { Row, Tag } from 'antd'
import React from 'react'
import { handleColors } from '../helpers/functions'
import { t } from 'i18next'

export const ExpectedAnswers = ({ data, type, theme }) => {
    return (
        <>
            {
                type === 'DR' ?
                    data.Expected_DR.map((item) => (
                        <>
                        <p style={{
                                    width: '95%', textAlign: 'left', margin: '0 0 1vh 0', color: theme ? '#00000060' : '#AAAAAA80'
                                }}>{item.Name_Label}</p>
                                <Row style={{
                                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                    marginBottom: '1vh'
                                }}>
                                    <div style={{
                                        width: '95%', border: theme ? '0px solid #00000040' : '0px solid #000', borderRadius: '0.5vh',
                                        padding: '2%', backgroundColor: theme ? '#fff' : '#2D2E32',
                                        color: theme ? '' : '#AAA'
                                    }}>
                                        {item.Value ? item.Value : 'null'}
                                    </div>
                                    
                                </Row>
                        </>
                    ))
                    : type === 'AD'
                        ?
                        <Row style={{
                            width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                        }}>
                            <p style={{
                                width: '', textAlign: 'left', margin: '0 0 1vh 0',
                                fontWeight: 600, color:theme ? ''  : '#AAAAAA80'
                                // color: '#00000060'
                            }}>{t('Drawer.ExpectedResponse')}: </p>
                            <Tag style={{
                                width: 'auto', alignItems: 'center', justifyContent: 'center',
                                width: '40%',
                                backgroundColor: theme ? `${handleColors(data.Expected_AD ? 'SENT' : 'ERROR')}80` : `${handleColors(data.Expected_AD ? 'SENT' : 'ERROR')}60`, 
                                border: `0px solid ${handleColors(data.Expected_AD ? 'SENT' : 'ERROR')}40`,
                                color: '#fff',
                                fontWeight: 400, borderRadius: '3vh',
                                height: '3vh',
                                display: 'flex'
                            }}>{data.Expected_AD ? 'Accepted' : 'Rejected'}</Tag>
                        </Row>

                        :
                        <Row style={{
                            width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'
                        }}>
                            <p style={{
                                width: '', textAlign: 'left', margin: '0 0 1vh 0',
                                fontWeight: 600, color:theme ? ''  : '#AAAAAA80'
                                // color: '#00000060'
                            }}>{t('Drawer.ExpectedResponse')}: </p>
                            <Tag style={{
                                width: 'auto', alignItems: 'center', justifyContent: 'center',
                                width: '40%',
                                backgroundColor: theme ? `${handleColors(data.Expected_TM ? 'SENT' : 'ERROR')}80` : `${handleColors(data.Expected_TM ? 'SENT' : 'ERROR')}60`, 
                                border: `0px solid ${handleColors(data.Expected_TM ? 'SENT' : 'ERROR')}40`,
                                color: '#fff',height: '3vh',
                                fontWeight: 400, borderRadius: '3vh',
                                display: 'flex'
                            }}>{data.Expected_TM ? 'Accepted' : 'Rejected'}</Tag>
                        </Row>
            }
        </>
    )
}
