import React, { useContext } from 'react'
import { appContext } from '../context'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from '../pages/LoginPage'
import { HomePage } from '../pages/HomePage'
import { ShipmentsPage } from '../pages/ShipmentsPage'
import { ReviewsPage } from '../pages/ReviewsPage'

export const PrivateHOC = ({ children }) => {

    const { logged } = useContext(appContext)

    return (logged)
    ? children
    : <Navigate to="/login" />
}

export const PublicHOC = ({ children }) => {

    const { logged } = useContext(appContext)

    return (!logged)
    ? children
    : <Navigate to="/home-page" />
}

export const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path="home-page" element={<HomePage />} />
            <Route path="shipments" element={<ShipmentsPage />} />
            <Route path="reviews" element={<ReviewsPage />} />
        </Routes>
    )
}

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="login" element={<LoginPage />} />
        </Routes>
    )
}
