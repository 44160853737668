import { appContext } from "./AuthContext"
import { authReducer } from "./authReducer"
import { types } from "./types"
import { useReducer } from "react"
import i18n from "../languages/i18n"
import { useNavigate } from "react-router-dom"
import { notification } from "antd"
import { t } from "i18next"

const init = () => {

    return {
        logged: !!!JSON.parse(localStorage.getItem('logged')),
        user: JSON.parse(localStorage.getItem('user')),
        theme: !!!JSON.parse(localStorage.getItem('theme')),
        device: true,
        language: !!!JSON.parse(localStorage.getItem('language')),
        previousConfigs: JSON.parse(localStorage.getItem('previous-configs'))
    }
}

export const AuthProvider = ({ children }) => {

    const [authState, dispatch] = useReducer(authReducer, {}, init)
    // const navigate = useNavigate()

    const login = (newUser = {}) => {

        const action = {
            type: types.LOGIN,
            payload: newUser
        }

        notification.open({
            message: `${t('Home.Greeting')} ${newUser.name.split(' ').slice(0, 2).join(' ')}!`,
            description: `${t('Home.Greeting.Message')}`,
            duration: 2,
            className: 'notification'
        })

        localStorage.setItem('user', JSON.stringify(newUser))
        localStorage.setItem('logged', JSON.stringify(true))
        dispatch(action)
    }

    const logout = () => {
        const action = {
            type: types.LOGOUT
        }

        // navigate("/login", {
        //     replace: true
        // })

        if (localStorage.getItem('user')) {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
        }
       
        dispatch(action) 
        localStorage.setItem('logged', JSON.stringify(false))
    }

    const handleTheme = (theme = true) => {
        const Theme = theme
        const action = {
            type: types.SWITHC_THEME,
            payload: theme
        }

        localStorage.setItem('theme', JSON.stringify(Theme))
        dispatch(action)
    }

    const handleDevice = (device) => {
        const Device = device
        const action = {
            type: types.SWITCH_DEVICE,
            payload: device
        }

        localStorage.setItem('device', JSON.stringify(Device))
        dispatch(action)
    }

    const handleLanguage = (language = true) => {
        const Language = language
        const action = {
            type: types.SWITCH_LANGUAGE,
            payload: language
        }

        if (language) i18n.changeLanguage('en')
        else i18n.changeLanguage('es')

        localStorage.setItem('language', JSON.stringify(Language))
        dispatch(action)
    }

    const handleConfigs = (configs) => {
        const PreviousConfigs = configs
        const action = {
            type: types.PREVIOUS_CONFIG,
            payload: PreviousConfigs
        }

        localStorage.setItem('previous-configs', JSON.stringify(PreviousConfigs))
        dispatch(action)
    }



    return (
        <appContext.Provider value={{
            ...authState,
            login,
            logout,
            handleTheme,
            handleLanguage,
            handleConfigs,
            handleDevice
        }}>
            {children}
        </appContext.Provider>

    )
}
