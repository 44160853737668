import { Button, Col, Row } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { appContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { IoIosSend } from "react-icons/io";
import { HiPencil } from "react-icons/hi2";
import { t } from 'i18next';
const Timer = ({ initialTime, theme }) => {
    const [time, setTime] = useState({
        minutes: parseInt(initialTime.split(':')[0], 10),
        seconds: parseInt(initialTime.split(':')[1], 10),
    });

    useEffect(() => {
        const timerID = setInterval(() => tick(timerID), 1000);

        return () => clearInterval(timerID);
    }, [time]);

    const tick = (timerID) => {
        setTime((prevTime) => {
            if (prevTime.minutes === 0 && prevTime.seconds === 0) {
                // Puedes realizar alguna acción cuando el temporizador llega a 00:00
                clearInterval(timerID);
                return prevTime;
            } else {
                return {
                    minutes: prevTime.seconds === 0 ? prevTime.minutes - 1 : prevTime.minutes,
                    seconds: prevTime.seconds === 0 ? 59 : prevTime.seconds - 1,
                };
            }
        });
    };

    return (
        <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            flexDirection: 'row', width: '100%'
        }}>
            <p style={{
                margin: 0, wordBreak: 'break-word',
                width: '70%', fontWeight: 700,
                fontSize: '1.4em', textAlign: 'left',
                color: theme ? '#FFF' : '#aaa'
            }}>{t('ShipReady')}</p>
            <p
                style={{
                    fontSize: '3.5em', margin: 0, fontWeight: 600,
                    width: '100%', textAlign: 'right', color: theme ? '#FFF' : '#AAA'
                }}>{`${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}</p>
        </div>
    );
};

const TimerApp = ({ time }) => {

    const { theme } = useContext(appContext)
    return (
        <Col style={{
            width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            flexDirection: 'column', marginTop: '-20vh',
            border: theme ? '0px solid #D9D9D9' : '0px solid #232426',
            padding: '3%', borderRadius: '4vh', backdropFilter: 'blur(2px)', backgroundColor: `${(theme) ? '#a8c1f490' : '#393B3F80'}`,
            // boxShadow: '0px 0px 12px #00000030'
        }}>
            {
                time ? <Timer initialTime={time} theme={theme} />
                    : <></>
            }
            <div
                className={theme ? 'loading-text' : 'loading-text-dark'}
                style={{
                    height: '2px', borderRadius: '3vh', width: '99%',
                    margin: '1vh 0 2vh 0'
                }} />

            <Row style={{
                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                flexDirection: 'row'
            }}>
                <Link to="/shipments"
                    style={{
                        width: '49.5%'
                    }}
                    target='_blank'>
                    <Button 
                    // icon={<IoIosSend style={{marginRight:'5px', color:'#333'}}/>}
                    style={{
                        width: '100%', 
                        // margin: '1vh 0 1vh 0',
                        backgroundColor: theme ? '#fff' : '#AAA',
                        fontWeight: 500, border: '0px solid #000',
                        borderRadius:'3vh 0 0 3vh',
                        color: '#333',
                        display:'flex', alignItems:'center', justifyContent:'center'
                    }}>
                        {t('StartNewShip')}
                    </Button>
                </Link>

                <Link to="/reviews"
                    style={{
                        width: '49.5%'
                    }}
                    target='_blank'>

                    <Button 
                    // icon={<HiPencil style={{marginRight:'5px', color:'#333'}}/>}
                    style={{
                        width: '100%', backgroundColor: theme ? '#fff' : '#AAA',
                        fontWeight: 500, border: '0px solid #000',
                        borderRadius:'0 3vh 3vh 0',
                        color: '#333',
                        display:'flex', alignItems:'center', justifyContent:'center'
                    }}>
                        {t('StartNewReview')}
                    </Button>

                </Link>



            </Row>



        </Col>
    );
};

export default TimerApp;
