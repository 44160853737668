import { Button, Input, Modal, Row, Table, Tag, Image, DatePicker, Form, Empty, Badge, Drawer, Col, message, Select, } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { BsFillCaretUpFill, BsFillCaretRightFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa"
import { BsBoxArrowUpRight } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import * as XLSX from 'xlsx'
import { saveAs } from "file-saver"
import useAxios from '../Hooks/UseAxios';
import { useTranslation } from 'react-i18next';
import { MdDownload } from "react-icons/md";
import { SubTable } from './SubTable';
import { colors } from '../helpers/colors';
import { logos } from '../helpers/logo';
import { getAllData, getCountries, getData, getDifficulties, getDocuments, getReport, getTest } from '../services/apiServices';
import { appContext } from '../context/AuthContext';
import { ReviewTestDrawer, ReviewedTestDrawer, ViewTestDrawer } from '../helpers/drawer';
import { formatDate, formatDocument, formatTime, handleColors } from '../helpers/functions';
const { Option } = Select

export const LatestShipments = () => {

    const { RangePicker } = DatePicker;

    const { response, loading, error, operation } = useAxios()
    const { theme } = useContext(appContext)
    const { t } = useTranslation();
    const [filterCountry, setFilterCountry] = useState([])
    const [filterDocuments, setFilterDocuments] = useState([])
    const [filterDifficulties, setFilterDifficulties] = useState([])
    const [tableReady, setTableReady] = useState(false)
    const [visible, setVisible] = useState(false)
    const [drawer, setDrawer] = useState(false)
    const [data, setData] = useState(null)
    const [reportData, setReportData] = useState([])
    const [date, setDate] = useState('')
    const [finalDate, setFinalDate] = useState('')
    const [reportDate, setReportDate] = useState([])
    const [curentTest, setCurentTest] = useState([])
    const [identityID, setIdentityID] = useState('')
    const [myTests, setMyTests] = useState('All tests')
    const [columns, setColumns] = useState(null)

    const [state, setState] = useState('READY')
    const [open, setOpen] = useState(false)
    const [reviewed, setReviewed] = useState(false)


    const MyColumns = [
        {
            title: `${t('Random.Table.Type')}`,
            width: '14%',
            dataIndex: 'Type_Of_Shipment',
            key: 'Type_Of_Shipment',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('Random.Table.Date')}`,
            width: '14%',
            dataIndex: 'CreationDate',
            key: 'CreationDate',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{formatDate(text)}</p>
        },
        {
            title: `${t('Random.Table.Time')}`,
            width: '14%',
            dataIndex: 'CreationDate',
            key: 'CreationDate',
            // fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{formatTime(text)}</p>
        },
        {
            title: `${t('LatestShipments.country')}`,
            width: '14%',
            dataIndex: 'Country',
            key: 'Country',
            filters: filterCountry,
            filterSearch: true,
            onFilter: (value, record) => record.Country.includes(value),
            onFilter: (text, record) => record.Country === text,
            // filterDropdown
            sorter: (a, b) => a.Country.localeCompare(b.Country),
            sortDirections: ['ascend'],
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('LatestShipments.document')}`,
            width: '17%',
            dataIndex: 'Document',
            key: 'Document',
            filters: filterDocuments,
            filterSearch: true,
            onFilter: (value, record) => formatDocument(record.Document).includes(value),
            onFilter: (text, record) => formatDocument(record.Document) === text,
            sorter: (a, b) => a.Document.localeCompare(b.Document),
            sortDirections: ['ascend'],
            render: (text) =>
                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <Tag

                        color={`${colors(text === 'NI' ? 1 : text === 'DL' ? 3 : 4)}${theme ? '80' : '40'}`}
                        style={{
                            color: theme ? '#fff' : '#aaaaaa',
                            border: `0px solid ${colors(text === 'NI' ? 1 : text === 'DL' ? 3 : 4)}60`,
                            textAlign: 'center', fontWeight: 400,
                            fontSize: '1.1em',
                            width: '90%', height: '3vh', borderRadius: '1.5vh',
                            // boxShadow: '0px 0px 6px #00000010'
                        }}>
                        {formatDocument(text)}
                    </Tag>
                </div>
        },
        {
            title: `${t('Random.Table.Attempts')}`,
            width: '13%',
            dataIndex: 'Quantity',
            key: 'Quantity',
            // filters: filterDocuments,
            // filterSearch: true,
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1.1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>



        },
        {
            title: `${t('Random.Table.Completed')}`,
            width: '13%',
            dataIndex: 'Total',
            key: 'Total',

            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1.1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>


        },
        {
            title: ``,
            width: '0%',
            dataIndex: 'key',
            key: 'key',
            // filters: filterDocuments,
            // filterSearch: true,
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                ></p>


        },

    ]

    const AllColumns = [
        {
            title: `Tester`,
            width: '16%',
            dataIndex: 'Tester',
            key: 'Tester',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('Random.Table.Type')}`,
            width: '14%',
            dataIndex: 'Type_Of_Shipment',
            key: 'Type_Of_Shipment',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('Random.Table.Date')}`,
            width: '14%',
            dataIndex: 'CreationDate',
            key: 'CreationDate',
            fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400,
                        color: `${theme ? '' : '#AAA'}`
                    }}
                >{formatDate(text)}</p>
        },
        {
            title: `${t('Random.Table.Time')}`,
            width: '12%',
            dataIndex: 'CreationDate',
            key: 'CreationDate',
            // fixed: 'left',
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{formatTime(text)}</p>
        },
        {
            title: `${t('LatestShipments.country')}`,
            width: '14%',
            dataIndex: 'Country',
            key: 'Country',
            filters: filterCountry,
            filterSearch: true,
            onFilter: (value, record) => record.Country.includes(value),
            onFilter: (text, record) => record.Country === text,
            // filterDropdown,
            sorter: (a, b) => a.Country.localeCompare(b.Country),
            sortDirections: ['ascend'],
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>
        },
        {
            title: `${t('LatestShipments.document')}`,
            width: '18%',
            dataIndex: 'Document',
            key: 'Document',
            filters: filterDocuments,
            filterSearch: true,
            onFilter: (value, record) => formatDocument(record.Document).includes(value),
            onFilter: (text, record) => formatDocument(record.Document) === text,

            sorter: (a, b) => a.Document.localeCompare(b.Document),
            sortDirections: ['ascend'],
            render: (text) =>
                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <Tag

                        color={`${colors(text === 'NI' ? 1 : text === 'DL' ? 3 : 4)}${theme ? '80' : '40'}`}
                        style={{
                            color: theme ? '#fff' : '#aaaaaa',
                            border: `0px solid ${colors(text === 'NI' ? 1 : text === 'DL' ? 3 : 4)}60`,
                            textAlign: 'center', fontWeight: 400,
                            fontSize: '1.1em',
                            width: '90%', height: '3vh', borderRadius: '1.5vh',
                            // boxShadow: '0px 0px 6px #00000010'
                        }}>
                        {formatDocument(text)}
                    </Tag>
                </div>
        },
        {
            title: `${t('Random.Table.Attempts')}`,
            width: '11%',
            dataIndex: 'Quantity',
            key: 'Quantity',
            // filters: filterDocuments,
            // filterSearch: true,
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1.1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>



        },
        {
            title: `${t('Random.Table.Completed')}`,
            width: '14%',
            dataIndex: 'Total',
            key: 'Total',

            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1.1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                >{text}</p>


        },
        {
            title: ``,
            width: '0%',
            dataIndex: 'key',
            key: 'key',
            // filters: filterDocuments,
            // filterSearch: true,
            render: (text) =>
                <p
                    style={{
                        textAlign: 'center', wordWrap: 'break-word',
                        fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                    }}
                ></p>


        },

    ]

    const expandedRowRender = (record) => {

        const expandedColumns = [

            {
                title: `${t('LatestShipments.identityID')}`,
                width: '10%',
                dataIndex: 'IdentityId',
                key: 'IdentityId',
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                //     <div style={{
                //         padding: 8, backgroundColor: `${theme ? '#fff' : '#222326'}`,
                //         border: `${theme ? '' : '2px solid #000'}`,
                //     }}>
                //         <Input
                //             placeholder="Search by identity ID"
                //             value={selectedKeys[0]}
                //             className='inputs-filters'
                //             onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                //             onPressEnter={confirm}
                //             style={{
                //                 width: 188, marginBottom: 8, display: 'block',
                //                 backgroundColor: `${theme ? '#fff' : '#2b2c2e'}`,
                //                 border: `${theme ? '' : '2px solid #000'}`,
                //                 color: `${theme ? '' : '#AAA'}`,

                //             }}
                //         />
                //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                //             <Button
                //                 type="primary"
                //                 onClick={confirm}
                //                 icon={<SearchOutlined />}
                //                 size="small"
                //                 style={{
                //                     width: 90, color: `${theme ? '' : '#fff'}`,
                //                     backgroundColor: `${theme ? '' : '#9078b5'}`
                //                 }}
                //             >
                //                 Search
                //             </Button>
                //             <Button onClick={clearFilters} size="small" style={{
                //                 width: 90, border: `${theme ? '' : '1.5px solid #000'}`,
                //                 backgroundColor: `${theme ? '' : '#2b2c2e'}`, color: `${theme ? '' : '#AAA'}`
                //             }}>
                //                 Clean
                //             </Button>
                //         </div>
                //     </div>
                // ),
                // filterIcon: (filtered) => (
                //     <SearchOutlined style={{ color: '#00000050' }} />
                // ),
                // onFilter: (value, record) => record.IdentityId.toLowerCase().includes(value.toLowerCase()),
                render: (value) =>
                    <p style={{
                        fontWeight: 400, color: `${theme ? '' : '#AAA'}`,
                        textAlign: 'center'
                    }}>{value}</p>
            },

            {
                title: `${t('LatestShipments.difficulty')}`,
                width: '5%',
                dataIndex: 'Difficulty',
                key: 'Difficulty',
                filters: filterDifficulties,
                filterSearch: true,
                onFilter: (value, record) => (record.Difficulty).includes(value),
                onFilter: (text, record) => (record.Difficulty) === text,
                sorter: (a, b) => a.Difficulty.localeCompare(b.Difficulty),
                sortDirections: ['ascend'],
                render: (text) =>
                    <p
                        style={{
                            textAlign: 'center', wordWrap: 'break-word',
                            fontSize: '1em', fontWeight: 400, color: `${theme ? '' : '#AAA'}`
                        }}
                    >{text}</p>

            },

            {
                title: `${t('Random.Table.Front')}`,
                dataIndex: 'Front',
                key: 'Front',
                width: '13%',
                render: (text) =>
                    <a href={text} target='_blank'
                        style={{
                            color: `${theme ? '#a8c1f4' : '#a8c1f490'}`, textAlign: 'justify', wordWrap: 'break-word',
                            fontSize: '0.9em', fontWeight: 400
                        }}
                    >{text}</a>

            },
            {
                title: `${t('Random.Table.Back')}`,
                dataIndex: 'Back',
                key: 'Back',
                width: '13%',
                render: (text) =>
                    <a href={text} target='_blank'
                        style={{
                            color: `${theme ? '#a8c1f4' : '#a8c1f490'}`, textAlign: 'justify', wordWrap: 'break-word',
                            fontSize: '0.9em', fontWeight: 400
                        }}
                    >{text}</a>

            },
            {
                title: `${t('LatestShipments.status')}`,
                width: '6%',
                dataIndex: 'Status',
                key: 'Status',
                fixed: 'right',
                // filters: [
                //     { text: 'ACCEPTED', value: true },
                //     { text: 'REJECTED', value: false },
                // ],
                // onFilter: (text, record) => record.Status === text,
                render: (text) => (
                    <>
                        {
                            text === 'ERROR'
                                ?
                                <Badge status="error" text={`${t('State.Error')}`} style={{
                                    color: theme ? '#ff4f5b' : '#ff4f5b80', textAlign: 'center', fontWeight: 600, fontSize: '1em',
                                    width: '90%',
                                }} />

                                : text === 'SENT' ? <Badge status="success" text={`${t('State.Sent')}`} style={{
                                    color: theme ? '#52c41a' : '#52c41a80', textAlign: 'center', fontWeight: 600, fontSize: '1em',
                                    width: '90%',
                                }} />

                                    : text === 'READY' ? <Badge status="warning" text={`${t('State.Ready')}`} style={{
                                        color: theme ? '#f0c145' : '#f0c14580', textAlign: 'center', fontWeight: 600, fontSize: '1em',
                                        width: '90%',
                                    }} />

                                        : <Badge status="success" text={`${t('State.Reviewed')}`} style={{
                                            color: theme ? '#52c41a' : '#52c41a', textAlign: 'center', fontWeight: 600, fontSize: '1em',
                                            width: '90%',
                                        }} />
                        }

                    </>
                )
            },
            {
                title: `${t('Random.Table.About')}`,
                width: '6%',
                dataIndex: '',
                key: '',
                fixed: 'right',
                render: (_, record) => (
                    <Row style={{
                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                        flexDirection: 'row'
                    }}>
                        <Button
                            style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                width: '90%', backgroundColor: theme ? '#a8c1f440' : '#393B3F', fontWeight: 500,
                                border: theme ? '0px solid #000' : '0px solid #cbdaf860',
                                borderRadius: '3vh', color: theme ? '' : '#AAA'
                            }}
                            icon={<BsBoxArrowUpRight style={{color: theme ? '' : '#AAA'}}/>}
                            className='prev'
                            onClick={() => handleTest(record.IdentityId)}>{t('Random.Table.Button')}</Button>

                    </Row>
                )
            },
        ];

        return <SubTable expandedColumns={expandedColumns} theme={theme} _id={record.key} />;
    }

    const customExpandIcon = ({ expanded, onExpand, record }) => {
        return expanded ? (
            <BsFillCaretUpFill onClick={(e) => onExpand(record, e)} style={{ cursor: 'pointer', color: theme ? '#00000080' : '', transition: 'all 0.55s ease-in-out', color: '#a8c1f4' }} />
        ) : (
            <BsFillCaretRightFill onClick={(e) => onExpand(record, e)} style={{ cursor: 'pointer', color: theme ? '#00000080' : '', transition: 'all 0.55s ease-in-out' }} />
        )
    }

    const handleTest = (id) => {
        if (id.length !== 24) {
            message.error(`${t('Home.Search.Message')}`)
            setIdentityID('')
        }
        else {
            // message.success(id.length)
            getTest(operation, id)
        }
    }

    const getInitialData = async () => {
        if (myTests === 'My tests') {
            await getData(operation, date, finalDate)
        } else {
            await getAllData(operation, date, finalDate)
        }
    }

    const fetchSimpleDate = () => {

        const today = new Date();
        today.setHours(today.getHours() - 6);
        const formattedDate = today.toISOString().split('T')[0];

        setDate(formattedDate)
        setFinalDate(formattedDate)

    }

    const handleReport = async (date) => {
        const formattedDate = date.toISOString().split('T')[0];
        setReportDate(formattedDate)
        // handleDownload(formatDate)
    }

    const handleDownload = (date) => {
        if (reportData) {
            const fileType =
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const formattedData = reportData.map((item) => {
                // Assuming you have columns 'name', 'age', 'email' in your data objects
                return {
                    Date: formatDate(item.CreationDate),
                    Time: formatTime(item.CreationDate),
                    Tester: item.Name,
                    Country: item.Country,
                    Document: item.Document,
                    Difficulty: item.Difficulty,
                    IdentityID: item.IdentityId,
                    DocumentID: item.TrainingDocumentId,
                    Front: item.Front,
                    Back: item.Back,
                    Status: item.Status ? 'ENVIADO' : 'ERROR',
                    Reason: item.Reason,
                };
            });

            const ws = XLSX.utils.json_to_sheet(formattedData);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: fileType });
            saveAs(dataBlob, `${date}${fileExtension}`);
            // setVisible(false)
        }

    }

    const applyFilter = (dates) => {
        if (dates) {
            setDate(dates[0].format('YYYY-MM-DD'))
            setFinalDate(dates[1].format('YYYY-MM-DD'))
        };
    }


    const getReportFunction = async (formattedDate) => {
        await getReport(operation, formattedDate)
    }

    const getCats = async () => {
        try {
            await Promise.all([
                getCountries(operation),
                getDocuments(operation),
                getDifficulties(operation)
            ]);
        } catch { }
    }

    useEffect(() => {
        // getInitialData()
        setFilterCountry([])
        setFilterDocuments([])
        setFilterDifficulties([])
        getCats()
    }, [date, finalDate])

    useEffect(() => {
        if (reportDate) {
            getReportFunction(reportDate)
        }
    }, [reportDate])

    useEffect(() => {
        if (myTests === 'My tests') {
            setColumns(MyColumns)
            getData(operation, date, finalDate)
        }

        else {
            setColumns(AllColumns)
            getData(operation, date, finalDate)
        }
    }, [myTests])

    useEffect(() => {
        // if (data) {
        //     getCats()
        // } 
        // else {

        // }
        if (data) {

        } else {
            if (myTests === 'My tests') {
                getData(operation, date, finalDate)
            } else {
                getAllData(operation, date, finalDate)
            }
        }

    }, [data]);

    useEffect(() => {
        if (filterCountry && filterDocuments && filterDifficulties) {
            if (filterCountry.length === 0 || filterDocuments.length === 0 || filterDifficulties.length === 0) {
                getCats()
            } else {
                getInitialData()
                if (myTests === 'All tests') setColumns(AllColumns)
                else setColumns(MyColumns)
            }
        }
    }, [filterCountry, filterDocuments, filterDifficulties])

    useEffect(() => {
        fetchSimpleDate()

    }, [])

    useEffect(() => {
        if (!loading) {
            switch (response.data.msg) {

                case "Get all countries":
                    const count = response.data.data;
                    setFilterCountry([])
                    const countryNames = count.map(country => country.Code_Country)
                    const sortedCountryNames = countryNames.sort();
                    const newFilterCountry = sortedCountryNames.map((country) => ({
                        text: `${country}`,
                        value: `${country}`,
                    }))
                    setFilterCountry([...newFilterCountry])
                    break;
                case "Get all difficulties":
                    const difficulties = response.data.data;
                    const filterDifficulties = difficulties.filter(doc => doc.Short_Name !== "E" & doc.Short_Name !== "M" & doc.Short_Name !== "N")
                    const AllDifficulties = filterDifficulties.map(difficulty => difficulty.Short_Name);
                    const sortedDifficulties = AllDifficulties.sort();
                    setFilterDifficulties([])
                    const newFilterDifficulty = sortedDifficulties.map((difficulty) => ({
                        text: `${difficulty}`,
                        value: `${difficulty}`,
                    }))
                    setFilterDifficulties([...newFilterDifficulty])
                    break

                case "Get all documents":
                    const documents = response.data.data;
                    setFilterDocuments([])
                    const documentsName = documents.map(document => document.Type_Document);
                    const sortedDocuments = documentsName.sort();
                    const newFilterDocument = sortedDocuments.map((doc) => ({
                        text: `${doc === 'NI' ? 'national-id' : doc === 'PS' ? 'passport' : doc === 'DL' ? 'drivers-license' : ''}`,
                        value: `${doc === 'NI' ? 'national-id' : doc === 'PS' ? 'passport' : doc === 'DL' ? 'drivers-license' : ''}`,
                    }))

                    setFilterDocuments([...newFilterDocument])
                    break
                case "Header ready":
                    if (response.data.Data) {
                        const values = response.data.Data
                        setData([])
                        console.log(values)
                        const reversedTests = values.slice().reverse()
                        reversedTests.map((doc) => {
                            console.log(doc)
                            const newDocument = {
                                key: doc._id,
                                Country: doc.Country,
                                Document: doc.Document,
                                CreationDate: doc.Creation_Date,
                                Tester: doc.Name,
                                State: doc.State,
                                Quantity: doc.Quantity,
                                Total: doc.Total,
                                Operation_Time: doc.Operation_Time,
                                Type_Of_Shipment: doc.Type_Of_Shipment
                            }

                            console.log(newDocument)
                            if (doc.Quantity > 0) setData((prevData) => [...prevData, newDocument]);
                        })
                    }
                    break
                case "Report ready":
                    if (response.data.Data) {
                        const values = response.data.Data;
                        setReportData([])
                        // console.log(values)
                        values.forEach((value) => {
                            if (value.Details && Array.isArray(value.Details)) {
                                value.Details.forEach((item) => {
                                    if (item.TrainingDocuments && Array.isArray(item.TrainingDocuments)) {
                                        item.TrainingDocuments.forEach((doc) => {
                                            const newItem = {
                                                CreationDate: value.CreationDate,
                                                Name: value.Name,
                                                Country: value.Country,
                                                Document: value.Document,
                                                Difficulty: item.Difficulty,
                                                IdentityId: item.Identity_Id,
                                                TrainingDocumentId: item.Training_Document_Id,
                                                Status: item.Send,
                                                Reason: item.Reason,
                                                Front: doc.Front,
                                                Back: doc.Back
                                            };
                                            // console.log(newItem)
                                            setReportData((prevData) => [...prevData, newItem]);
                                        })
                                    }

                                });
                            }
                        });
                    }
                    break
                case "Specific test detail ready":
                    if (response.data.Data) {
                        if (response.data.msg === "Specific test detail ready") {
                            const values = response.data.Data;
                            if (values.length > 0) {
                                values.map((test) => {
                                    const newTest = {
                                        IdentityID: test.Identity_Id,
                                        DocumentID: test.Training_Document_Id,
                                        Verification: test.Verification_Number,
                                        Difficulty: test.Difficulty,
                                        Name: test.Name_Doc[0],
                                        Country: test.Country[0],
                                        Document: test.Document[0],
                                        Front: test.Front[0],
                                        Back: test.Back[0],
                                        ShipmentDate: test.Shipment_Date,
                                        ReviewDate: test.Review_Date,
                                        SentBy: test.CreatedBy[0],
                                        ReviewedBy: test.Last_Update_By[0],
                                        Status: test.Status,
                                        Reason: test.Reason
                                    }
                                    setCurentTest(newTest)
                                    setDrawer(true)
                                    setIdentityID('')
                                })
                            } else {
                                message.error(`${t('Home.Search.Message-2')}`)
                                setIdentityID('')
                            }

                        }
                    }
                    break
                default:
                    break;
            }
            setTimeout(() => {
                setTableReady(true)
            }, 1000);
        }
    }, [response])

    useEffect(() => {
        if (myTests === "All tests") {
            setColumns(AllColumns)
        } else setColumns(MyColumns)
    }, [theme])



    return (
        <>

            {
                tableReady
                    ? <div style={{
                        flexDirection: 'column', display: 'flex', alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        width: '100%', marginTop: '6vh',
                    }}>
                        
                        <Row style={{
                            width: '100%', display: 'flex',
                            alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row',
                            marginBottom: '3vh',
                        }}>
                            <Row>

                                <Select
                                    value={myTests}
                                    onChange={(e) => setMyTests(e)}
                                    placeholder="My tests"
                                    // className={`${theme ? 'random-light' : 'random-dark'}`}
                                    className={theme ? 'my-tests-select' : 'my-tests-select-dark'}
                                    popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                                    style={{ width: '15vh', height: '4.5vh', }}
                                    dropdownStyle={{
                                        backgroundColor: theme ? '' : '#CCD5DC'
                                    }}
                                >
                                    <Option key={'test'} value={'My tests'}>
                                        {t('Reviews.select.mytests')}
                                    </Option>

                                    <Option key={'all'} value={"All tests"}>
                                        {t('Reviews.select.alltests')}
                                    </Option>
                                </Select>
                                <RangePicker
                                    onChange={applyFilter}
                                    className={theme ? '' : 'picker'}
                                    // dropdownAlign={{backgroundColor:'red'}}
                                    style={{
                                        height: '4.5vh', marginLeft: '10px',
                                        borderRadius: '3vh',
                                        backgroundColor: `${theme ? '' : '#27282c'}`,
                                        // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`,
                                        border: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                                        // color: `${theme ? '' : '#AAA !important'}`,
                                    }} />


                            </Row>

                            <Row>
                                <Row style={{
                                    marginLeft: '10px', position: 'relative', display: 'flex',
                                    alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                }}>
                                    <Input
                                        onChange={(e) => setIdentityID(e.target.value)}
                                        value={identityID}
                                        
                                        placeholder={t('Home.Search.Placeholder')}
                                        style={{
                                            width: '30vh', borderRadius: '3vh 0 0 3vh',
                                            // height: '4.5vh',
                                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                                            borderTop: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                                            borderRight: theme ? '0px solid #000' : '0px solid #232426',
                                            borderBottom: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                                            borderLeft: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                                            color: `${theme ? '#333' : '#AAA'}`,
                                            // fontWeight: 500,
                                            backgroundColor: `${theme ? '#ffffff' : '#27282c'}`,
                                        }} />
                                    <Button
                                        className='prev'
                                        icon={<FaSearch size={12} style={{ color: theme ? '#00000070' : '#333' }} />}
                                        onClick={() => handleTest(identityID)} style={{
                                            // borderRadius: '0 3vh 3vh 0', height: '4.5vh',
                                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                                            border: theme ? '0px solid #D9D9D9' : '1px solid #232426',
                                            borderRadius: '50%', position: 'absolute', left: '94%',
                                            // border: theme ? '0px solid #000' : '0px solid #000',
                                            backgroundColor: theme ? '#D6CFFD' : '#635E8A',
                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                        }} />

                                </Row>

                                <Button
                                    className='prev'
                                    icon={<MdDownload size={16} style={{ color: theme ? '#00000070' : `#333`, }} />}
                                    onClick={() => setVisible(true)}
                                    style={{
                                        marginLeft: '25px',
                                        // border: theme ? '0px solid #000' : '0px solid #000',
                                        border: theme ? '0px solid #D9D9D9' : '1px solid #232426',
                                        borderRadius: '50%',
                                        backgroundColor: theme ? '#BEEFE3' : '#6E8F8A',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }} />

                                <Button
                                    className='prev'
                                    icon={<BiRefresh size={18} style={{ color: theme ? '#00000070' : '#333', }} />}
                                    onClick={getInitialData}
                                    style={{
                                        marginLeft: '5px',
                                        // border: theme ? '0px solid #000' : '0px solid #000',
                                        border: theme ? '0px solid #D9D9D9' : '1px solid #232426',
                                        borderRadius: '50%',
                                        backgroundColor: theme ? '#F9AFCD' : '#946D7F',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }} />

                            </Row>


                        </Row>
                        <div style={{

                            width: 'auto',
                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`,
                            border: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                            borderRadius: '1vh', height: '70vh', width: '100%',
                            display: 'flex', alignItems: `${data ? 'flex-start' : 'center'}`, justifyContent: 'center', 
                            backgroundColor: `${theme ? '#ffffff' : '#2A2A2E'}`, marginBottom: '3vh'
                        }}>

                            {
                                data ?
                                    <Table
                                        style={{
                                            width: '100%', borderRadius:'3vh'
                                        }}
                                        // className={}
                                        className={`${theme ? 'latest-shipments' : 'my-table'}`}
                                        // className={theme ? 'latest-shipments' : 'my-table-revship'}
                                        columns={columns}
                                        expandable={{
                                            expandedRowRender: expandedRowRender, // Asigna la función expandedRowRender
                                            defaultExpandedRowKeys: ['0'],
                                            expandIcon: customExpandIcon
                                        }}
                                        dataSource={data}
                                        scroll={{
                                            y: '60vh',
                                            // x: '240vh',
                                            x: '130vh'
                                        }}
                                        pagination={false} />

                                    : <Empty
                                        description={<p style={{
                                            fontWeight: 600, color: '#AAAAAA90', fontSize: '1.2em',
                                        }}>{t('Random.Table.warning')}</p>}
                                        imageStyle={{ height: '110%' }}
                                        style={{
                                            width: '68vw'
                                        }} />
                            }



                        </div>
                    </div>

                    : <div style={{ height: '90vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                        <img src={logos.rainbow} style={{ marginTop: '-20vh', width: '10%' }} />

                    </div>
            }

            <Modal
                open={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={<></>}
            >
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'column', width: '100%',
                    margin: '0'
                }}>
                    <Row style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                        width: '90%'
                    }}>
                        <p style={{
                            width: '50%', textAlign: 'left', fontSize: '1.2em', fontWeight: 700, fontStyle: 'italic'
                        }}>{t('DailyReport.Title')}</p>
                        <DatePicker onChange={handleReport} />
                    </Row>

                    <hr style={{
                        width: '100%', border: '2px solid #00000010'
                    }} />



                    <p style={{
                        width: '90%', textAlign: 'left', fontWeight: 400, margin: 0, color: '#AAA'
                    }}>{t('DailyReport.Text')}</p>
                    <p style={{
                        width: '90%', textAlign: 'left', fontWeight: 400, margin: 0, color: '#AAA'
                    }}>{t('DailyReport.subtext1')}</p>
                    <p style={{
                        width: '90%', textAlign: 'left', fontWeight: 400, margin: 0, color: '#AAA'
                    }}>{t('DailyReport.subtext2')}</p>
                    <p style={{
                        width: '90%', textAlign: 'left', fontWeight: 400, margin: 0, color: '#AAA'
                    }}>{t('DailyReport.subtext3')}</p>


                    <Row style={{ width: '90%', marginTop: '5vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>



                        <Button onClick={() => handleDownload(reportDate)} style={{

                            backgroundColor: '#D8F7F2', 
                            color: '#6E8F8A',
                            border:'0px solid #000',
                            fontWeight: 500, borderRadius:'3vh'
                        }}>
                            {t('DailyReport.Download')}
                        </Button>

                        <Button
                            type='ghost'
                            onClick={() => setVisible(false)}
                            style={{

                                color: '#6E8F8A',
                                fontWeight: 500
                            }}>
                            {t('DailyReport.Cancel')}
                        </Button>




                    </Row>
                </div>


            </Modal>

            <ViewTestDrawer theme={theme} curentTest={curentTest} drawer={drawer} setDrawer={setDrawer} setOpen={setOpen} setReviewed={setReviewed} />
            <ReviewTestDrawer theme={theme} identityID={curentTest.IdentityID} drawer={open} setDrawer={setOpen} setState={setState} />
            <ReviewedTestDrawer theme={theme} drawer={reviewed} setDrawer={setReviewed} curentTest={curentTest} />


        </>
    )
}
