
import { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { VscDebugStart } from "react-icons/vsc";
import { Button } from "antd"
import { t } from 'i18next';
import { appContext } from "../context/AuthContext";
import { BiRightArrow } from "react-icons/bi";
import { FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";

export const HomeButtons = () => {

    // const theme = JSON.parse(localStorage.getItem('theme'))
    const { theme } = useContext(appContext)
    const navigate = useNavigate()
    const [selectedItem, setSelectedItem] = useState(0)
    const [randomText, setRandomText] = useState(true);
    const [customerText, setCustomerText] = useState(false);
    const [reviewText, setReviewText] = useState(false);
    const [randombg, setRandombg] = useState(true)
    const [customerbg, setCustomerbg] = useState(true)
    const [reviewbg, setReviewbg] = useState(true)
    const [onRandom, setOnRandom] = useState(false)
    const [onReviews, setOnReviews] = useState(false)
    const [onMetrics, setOnMetrics] = useState(false)


    const showText = (index) => {
        switch (index) {
            case 0:

                setCustomerText(false)
                setReviewText(false)
                setTimeout(() => {
                    setRandomText(true);
                }, 300);

                break;

            case 1:

                setRandomText(false)
                setReviewText(false)
                setTimeout(() => {
                    setCustomerText(true);
                }, 300);

                break;

            case 2:

                setRandomText(false)
                setCustomerText(false)
                setTimeout(() => {
                    setReviewText(true);
                }, 300);

                break;

            default:
                break;
        }

    }

    const handleItemClick = (index) => {
        setSelectedItem(index);
        showText(index)

    }

    const handleToTest = (n) => {
        switch (n) {
            case 0: navigate("/shipments", { replace: true })
                break

            // case 1: navigate("/customer-complaint", { replace: true })
            //     break

            // case 2: navigate("/test-review", { replace: true })
            //     break

            // default: navigate("/home-page", { replace: true })
            //     break

        }

    }

    const handleRandomBakcground = () => {
        if (randombg) setRandombg(false)
        else setRandombg(true)
    }


    const handleCustomerBakcground = () => {
        if (customerbg) setCustomerbg(false)
        else setCustomerbg(true)
    }

    const handleReviewBakcground = () => {
        if (reviewbg) setReviewbg(false)
        else setReviewbg(true)
    }


    const textReview = t('Home.reviewText')
    const textRandom = t('Home.randomText')
    const textCustomer = t('Home.customerText')

    const data = [
        {
            type: 'Sent',
            value: 27,
        },
        {
            type: 'Reviewd',
            value: 25,
        },
    ];

    const customColors = ['#7fcac4', '#6edfc7'];

    const config = {

        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.39,
        color: customColors,
        tooltip: false,
        label: {
            type: 'inner',
            offset: '-50%',
            content: ({ percent, value }) => `${value}`,
            style: {
                fontWeight: 'bolder',
                fill: '#000000',
                height: '80%',
                textAlign: 'center',
                fontSize: 25,
                border: '1.5px solid #000',
                // lineColor: 'black',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            },
        },
        style: {
            stroke: '#000000', // Color del borde (negro en este caso)
            lineWidth: 3, // Ancho del borde
        },

        legend: false,

    };


    return (

        <>
            <div className="slider-container home-buttons"
                style={{
                    flexDirection: 'row',
                    width: '100%', marginTop: '6vh', marginBottom: '5vh',
                }} >

                {/* <Button onClick={nonHomeLocation()}>nonHome</Button> */}

                < div
                    style={{ position: 'relative', backgroundColor: 'transparent' }
                    }
                    className={`slider-item ${selectedItem === 0 ? 'selected' : ''}`}
                    onMouseMove={() => handleItemClick(0)}

                >

                    <div
                        style={{
                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                            border: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                            borderRadius: '3vh', display: 'flex', flexDirection: 'row', transition: 'all 0.55s ease-in-out',
                            alignItems: 'center', justifyContent: 'center', height: '30vh', backgroundColor: `${theme ? '#ffffff' : '#27282c'}`,
                            // backdropFilter: 'blur(2px)',
                        }}
                    >

                        {
                            selectedItem === 0
                                ?
                                <>
                                    <div className="shipment-prueba" onMouseEnter={handleRandomBakcground} onMouseLeave={handleRandomBakcground}
                                        style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'default' }}>

                                        <div className="random-shipment-img" style={{
                                            height: '90%', width: '80%', borderRadius: '2vh',
                                            backgroundColor: `${(randombg) ? theme ? '#aca0fa80' : '#aca0fa70' : theme ? '#dcd8f7' : '#aca0fa70'}`
                                        }} />

                                    </div>

                                    <div
                                        style={{
                                            height: '100%', width: '45%', flexDirection: 'column', backgroundColor: 'transparent', borderRadius: '1.5vh',
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', color: `${theme ? '#000' : '#e3e3e3'}`, transition: 'all 0.55s ease-in-out',
                                            cursor: 'default',
                                        }}>

                                        <div style={{ height: '15%', }}>
                                            <h1 style={{ fontSize: 15, fontStyle: 'italic', color: `${theme ? '' : '#aaa'}` }}>{t('Home.buttonRandom')}</h1>
                                        </div>

                                        <div style={{ height: '35%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                            <p style={{
                                                fontStyle: 'italic',
                                                fontSize: 14, color: `${theme ? '' : '#aaa'}`, textAlign:'justify'
                                            }}
                                            >{(randomText) ? textRandom : ''}</p>
                                        </div>

                                        <div style={{ height: '20%', }}>
                                            {
                                                (randomText)
                                                    ? <Link to="/shipments">

                                                        <Button
                                                            icon={!onRandom ?
                                                                <FaAngleRight size={18} style={{ marginRight: '5px', color: '#333' }} /> :
                                                                <FaAngleDoubleRight size={18} style={{ marginRight: '5px', color: '#333' }} />}
                                                            onMouseEnter={() => setOnRandom(true)} onMouseLeave={() => setOnRandom(false)}
                                                            style={{
                                                                backgroundColor: `${theme ? '#aca0fa90' : '#aca0fa80'}`, width: 180, borderRadius: '3vh',
                                                                color: '#333', fontWeight: 'bold', marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                border: '0px solid #000'
                                                            }}
                                                            className="random-home" >
                                                            {t('Home.randomStar')}
                                                        </Button>
                                                    </Link>
                                                    : <></>
                                            }

                                        </div>

                                    </div>

                                </>

                                :
                                <div className="shipment-prueba"
                                    style={{
                                        height: '100%', width: '100%', backgroundColor: `${theme ? '#ffffff80' : '#27282c'}`, borderRadius: '3vh',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'all 0.55s ease-in-out'
                                    }}>
                                    <div className="random-shipment-img" style={{ height: '90%', width: '90%', borderRadius: '2vh', backgroundColor: `${(randombg) ? theme ? '#aca0fa80' : '#aca0fa70' : theme ? '#dcd8f780' : '#aca0fa70'}` }} />
                                </div>
                        }

                    </div>

                </div >


                <div
                    style={{
                        // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`,
                        borderRadius: '3vh', display: 'flex', flexDirection: 'row',
                        border: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                        alignItems: 'center', justifyContent: 'center', height: '30vh', backgroundColor: `${theme ? '#ffffff' : '#27282c'}`, cursor: 'default'
                    }}
                    className={`slider-item ${selectedItem === 1 ? 'selected' : ''}`}
                    onMouseMove={() => handleItemClick(1)}  >

                    {
                        selectedItem === 1
                            ?
                            <>
                                <div className="shipment-prueba"
                                    onMouseEnter={handleCustomerBakcground} onMouseLeave={handleCustomerBakcground}
                                    style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                    <div className="customer-complaint-img" style={{
                                        height: '90%', width: '80%', borderRadius: '2vh',
                                        backgroundColor: `${(customerbg) ? theme ? '#6edfc780' : '#b0f0e380' : theme ? '#b0f0e3' : '#b0f0e380'}`
                                    }} />
                                </div>

                                <div
                                    style={{
                                        transition: 'all 0.55s ease-in-out',
                                        height: '100%', width: '45%', borderRadius: '1.5vh', flexDirection: 'column', backgroundColor: 'transparent', borderRadius: '1.5vh',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', color: `${theme ? '#000' : '#e3e3e3'}`, cursor: 'default'
                                    }}>

                                    <div style={{ height: '15%', }}>
                                        <h1 style={{ fontSize: 15, fontStyle: 'italic', color: `${theme ? '' : '#aaa'}` }}>{t('Home.buttonCustomer')}</h1>
                                    </div>

                                    <div style={{ height: '40%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                        <p style={{
                                            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", fontStyle: 'italic',
                                            fontSize: 14, color: `${theme ? '' : '#aaa'}`
                                        }}
                                        >{(customerText) ? textCustomer : ''}</p>
                                    </div>

                                    <div style={{ height: '20%', }}>
                                        {
                                            (customerText)

                                                ?
                                                <Link to="/reviews">

                                                    <Button
                                                        icon={!onReviews ?
                                                            <FaAngleRight size={18} style={{ marginRight: '5px', color: '#333' }} /> :
                                                            <FaAngleDoubleRight size={18} style={{ marginRight: '5px', color: '#333' }} />}
                                                        onMouseEnter={() => setOnReviews(true)} onMouseLeave={() => setOnReviews(false)}
                                                        style={{
                                                            backgroundColor: theme ? '#BEEFE3' : '#6edfc780', width: 180, borderRadius: '3vh',
                                                            color: '#333', fontWeight: 'bold', marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                            border: '0px solid #000'
                                                        }}
                                                        className="customer-home" >
                                                        {t('Home.customerStar')}
                                                    </Button>
                                                </Link>

                                                : <></>
                                        }

                                    </div>

                                </div>

                            </>

                            :
                            <div className="shipment-prueba"
                                style={{ height: '100%', width: '100%', backgroundColor: `${theme ? '#ffffff80' : '#27282c'}`, borderRadius: '3vh', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'all 0.55s ease-in-out' }}>
                                <div className="customer-complaint-img" style={{
                                    height: '90%', width: '90%', borderRadius: '2vh', backgroundColor: `${(customerbg) ? theme ? '#6edfc780' : '#b0f0e380 ' : theme ? '#b0f0e380' : '#b0f0e380'}`, display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {/* <Pie {...config} /> */}
                                </div>
                            </div>
                    }




                </div>



                <div
                    style={{ position: 'relative', backgroundColor: 'transparent' }
                    }
                    className={`slider-item ${selectedItem === 2 ? 'selected' : ''}`}
                    onMouseMove={() => handleItemClick(2)}

                >

                    <div
                        style={{
                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                            border: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                            borderRadius: '3vh', display: 'flex', flexDirection: 'row', transition: 'all 0.55s ease-in-out',
                            alignItems: 'center', justifyContent: 'center', height: '30vh', backgroundColor: `${theme ? '#ffffff' : '#27282c'}`,
                            // backdropFilter: 'blur(2px)',
                        }}
                    >

                        {
                            selectedItem === 2
                                ?
                                <>
                                    <div className="shipment-prueba" onMouseEnter={handleReviewBakcground} onMouseLeave={handleReviewBakcground}
                                        style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'default' }}>

                                        <div className="random-test-img" style={{
                                            height: '90%', width: '80%', borderRadius: '2vh',
                                            backgroundColor: `${(reviewbg) ? theme ? '#ef83ae80' : '#f9afcd80' : theme ? '#f9afcd' : '#f9afcd80'}`
                                        }} />

                                    </div>

                                    <div
                                        style={{
                                            height: '100%', width: '45%', flexDirection: 'column', backgroundColor: 'transparent', borderRadius: '1.5vh',
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', color: `${theme ? '#000' : '#e3e3e3'}`, transition: 'all 0.55s ease-in-out',
                                            cursor: 'default'
                                        }}>

                                        <div style={{ height: '15%', }}>
                                            <h1 style={{ fontSize: 15, fontStyle: 'italic', color: `${theme ? '' : '#aaa'}` }}>{t('Home.buttonReview')}</h1>
                                        </div>

                                        <div style={{ height: '35%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                            <p style={{
                                                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", fontStyle: 'italic',
                                                fontSize: 14, color: `${theme ? '' : '#aaa'}`
                                            }}
                                            >{(reviewText) ? textReview : ''}</p>
                                        </div>

                                        <div style={{ height: '20%', }}>
                                            {
                                                (reviewText)
                                                    ? <Link to="">

                                                        <Button
                                                            icon={!onMetrics ?
                                                                <FaAngleRight size={18} style={{ marginRight: '5px', color: '#333' }} /> :
                                                                <FaAngleDoubleRight size={18} style={{ marginRight: '5px', color: '#333' }} />}
                                                            onMouseEnter={() => setOnMetrics(true)} onMouseLeave={() => setOnMetrics(false)}
                                                            style={{
                                                                backgroundColor: theme ? '#F9C3D7' : '#ef83ae80', width: 180, borderRadius: '3vh',
                                                                color: '#333', fontWeight: 'bold', marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                border: '0px solid #000'
                                                            }}
                                                            className="review-home" >
                                                            {t('Home.reviewStar')}
                                                        </Button>
                                                    </Link>
                                                    : <></>
                                            }

                                        </div>

                                    </div>

                                </>

                                :
                                <div className="shipment-prueba"
                                    style={{
                                        height: '100%', width: '100%', backgroundColor: `${theme ? '#ffffff80' : '#27282c80'}`, borderRadius: '3vh',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'all 0.55s ease-in-out'
                                    }}>
                                    <div className="random-test-img" style={{ height: '90%', width: '90%', borderRadius: '2vh', backgroundColor: `${(reviewbg) ? theme ? '#f9afcd80' : '#f9afcd80' : theme ? '#f9afcd80' : '#f9afcd80'}` }} />
                                </div>
                        }

                    </div>

                </div >



            </div >

            <div
                className="home-buttons-2"
                style={{
                    alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                    width: '100%', marginTop: '5vh', marginBottom: '5vh',
                    // padding:'0 10% 0 10%'
                }}>

                <div
                    className="div-searcher"
                    style={{
                        transition: 'all 0.55s ease-in-out',
                        height: '30vh', width: '31%',
                        // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                        borderRadius: '1.5vh',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: `${theme ? '#aca0fa80' : '#aca0fa60'}`,
                        ackdropFilter: 'blur(2px)',
                    }}>
                    <div className="random-shipment-img"
                        style={{
                            height: '100%', borderRadius: '1.5vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            // backgroundColor: `${theme? '#aca0fa':'#aca0fa80'}`
                        }}>

                        <Link to="/shipments" style={{
                            marginBottom: '-20vh'
                        }}>
                            <Button
                                className="div-searcher"
                                style={{
                                    backgroundColor: `${theme ? '#dcd8f780' : '#8a84be60'}`, width: '30vh', height: '5vh',
                                    color: '#000', lineHeight: '0em', border: '0px solid #000',
                                    boxShadow: '0px 0px 8px #00000030', ackdropFilter: 'blur(8px)',
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <VscDebugStart size={20} />
                                    <p style={{ fontWeight: 'bold' }}>{t('Home.randomStar')}</p>
                                </div>

                            </Button>
                        </Link>
                    </div>


                </div>

                <div
                    className="div-searcher"
                    style={{
                        transition: 'all 0.55s ease-in-out',
                        height: '30vh', width: '31%', borderRadius: '1.5vh',
                        // margin: '0 3vh 0 3vh',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: `${theme ? '#6edfc780' : '#6edfc760'}`,
                        // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`,
                        ackdropFilter: 'blur(2px)',
                    }}>
                    <div className="customer-complaint-img"
                        style={{
                            height: '100%', borderRadius: '1.5vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            // backgroundColor: '#6edfc7'
                        }}>

                        <Link to="/reviews" style={{
                            marginBottom: '-20vh'
                        }}>
                            <Button
                                className="div-searcher"
                                style={{
                                    backgroundColor: `${theme ? '#b0f0e380' : '#61a49860'}`, width: '30vh', height: '5vh',
                                    color: '#000', lineHeight: '0em', border: '0px solid #000', ackdropFilter: 'blur(8px)',
                                    boxShadow: '0px 0px 8px #00000030'
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <VscDebugStart size={20} />
                                    <p style={{ fontWeight: 'bold' }}>{t('Home.customerStar')}</p>
                                </div>

                            </Button>
                        </Link>
                    </div>


                </div>

                <div
                    className="div-searcher"
                    style={{
                        transition: 'all 0.55s ease-in-out',
                        height: '30vh', width: '31%',
                        // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                        borderRadius: '1.5vh',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: `${theme ? '#ef83ae80' : '#ef83ae60'}`,
                        ackdropFilter: 'blur(2px)',
                    }}>
                    <div className="random-test-img"
                        style={{
                            height: '100%', borderRadius: '1.5vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            // backgroundColor: '#ef83ae'
                        }}>

                        <Link to="" style={{
                            marginBottom: '-20vh'
                        }}>
                            <Button
                                className="div-searcher"
                                style={{
                                    backgroundColor: theme ? '#f9afcd80' : '#ae6d8860', width: '30vh', height: '5vh',
                                    color: '#000', lineHeight: '0em', border: '0px solid #000',
                                    boxShadow: '0px 0px 8px #00000030', ackdropFilter: 'blur(8px)',
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <VscDebugStart size={20} />
                                    <p style={{ fontWeight: 'bold' }}>{t('Home.reviewStar')}</p>
                                </div>

                            </Button>
                        </Link>
                    </div>


                </div>


            </div>

            <div
                className="home-buttons-3"
                style={{
                    alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
                    width: '100%', marginTop: '5vh', marginBottom: '5vh', flexDirection: 'column'
                }}>

                <Link to="/shipments" style={{
                }}>
                    <Button
                        className="div-searcher"
                        style={{
                            backgroundColor: theme ? '#aca0fa80' : '#aca0fa80', width: '45vh', height: '10vh',
                            color: '#000', lineHeight: '0em', borderRadius: '2vh'
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <VscDebugStart size={20} />
                            <p style={{ fontWeight: 'bold' }}>{t('Home.randomStar')}</p>
                        </div>

                    </Button>
                </Link>

                <Link to="reviews" style={{

                }}>
                    <Button
                        className="div-searcher"
                        style={{
                            backgroundColor: theme ? '#6edfc780' : '#6edfc780', width: '45vh', height: '10vh',
                            color: '#000', lineHeight: '0em', margin: '3vh 0 3vh 0', borderRadius: '2vh'
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <VscDebugStart size={20} />
                            <p style={{ fontWeight: 'bold' }}>{t('Home.customerStar')}</p>
                        </div>

                    </Button>
                </Link>

                <Link to="" style={{
                }}>
                    <Button
                        className="div-searcher"
                        style={{
                            backgroundColor: theme ? '#ef83ae80' : '#ef83ae80', width: '45vh', height: '10vh',
                            color: '#000', lineHeight: '0em', borderRadius: '2vh'
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <VscDebugStart size={20} />
                            <p style={{ fontWeight: 'bold' }}>{t('Home.reviewStar')}</p>
                        </div>

                    </Button>
                </Link>

            </div>

        </>

    )
}
