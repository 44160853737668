import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, FloatButton, Layout, Row } from 'antd';


import { useTranslation } from 'react-i18next';

import { LatestShipments } from '../../components/LatestShipments';
import { HomeButtons } from '../../components/HomeButtons';
import { appContext } from '../../context/AuthContext';

const { Content } = Layout;

export const ContentApp = (props) => {


    // const theme = JSON.parse(localStorage.getItem('theme'))
    const { theme } = useContext(appContext)
    const [homeState, setHomeState] = useState(Math.floor(Math.random() * 3))
    // const { theme } = useContext( AuthContext )
    const { t } = useTranslation();


    const handleColors = (index) => {
        switch (index) {
            case 0: return '#d1c8f780'
            case 1: return '#fff0e780'
            case 2: return '#fcd9e780'
            case 3: return '#ccf2f380'
            // default: '#fff'
        }
    }

    useEffect(() => {
        setHomeState(Math.floor(Math.random() * 3))
    }, [])



    return (
        <Content className='scrollable-div'
            style={{
                marginTop: '12vh', // Ajusta este valor para que el contenido no quede detrás del header
                marginBottom: '2vh', // Ajusta este valor para que el contenido no quede detrás del footer
                overflowY: 'scroll',
                height: 'auto', // Ajusta estos valores según el header y footer
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                flexDirection: 'column', padding: '0 0 0 0',
                backgroundColor: 'transparent'
            }}
        >
            <FloatButton.BackTop visibilityHeight={0} style={{ right: '50px', opacity: theme ? '0.5' : '0.1', }} />

            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                flexDirection: 'column', height: 'auto', width: 'auto', flexWrap: 'wrap',

            }}>
                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
                    width: '85%'
                }}>

                    {/* <div
                        // onMouseEnter={handleFunctions}
                        className={`${theme ? `image-home-${homeState} home-move` : `dark-image-home-${homeState} home-move`}`}
                        style={{ width: '30%', height: '24vh', marginLeft:'-60px' }} /> */}

                    <h2
                        className="header-title"
                        style={{
                            color: `${theme ? '#333333' : handleColors(homeState)}`,
                            fontFamily: " Tahoma, Geneva, Verdana, sans-serif", transition: 'all 0.35s ease-in-out',
                            wordWrap: 'break-word', fontSize:'5em',
                            WebkitTextStroke: `2px  ${theme ? '#333333' : handleColors(homeState)}`,
                            fontWeight: 'bolder', width:'95%', textAlign:'center', marginTop:'65px'
                        }}>
                        {t('Home.text')}</h2>

                </Row>

                {/* <hr style={{
                    width:'85%', margin:'5vh 0 -3vh 0', border:'1.5px solid #00000040'
                }}/> */}

                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                    width: '85%', marginTop: '5vh',
                }}>
                    <h2
                        // className="lets-start-sending"
                        style={{
                            fontWeight: 'bold', fontStyle: 'italic', color: `${theme ? '#000' : '#aaa'}`,
                            marginBottom: '-2vh', width: '100%',
                        }}>{t('Home.lestsStart')}</h2>
                    <HomeButtons />

                </div>

                {/* <LatestShipments /> */}

                <div
                    className="home-table"
                    style={{
                        alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                        width: '85%', height: 'auto', marginTop: '3vh'
                    }}>
                    <h2 style={{
                        fontWeight: 'bold', fontStyle: 'italic', color: `${theme ? '#000' : '#aaa'}`,
                        marginBottom: '-2vh', width: '100%',
                    }}>{t('Home.latestShipment')}</h2>
                    <LatestShipments />

                </div>
            </div>


        </Content>
    )
}
