

export function colors(index) {
    switch (index) {
        case 6: return '#ffee90'
        case 5: return '#7e6cff'
        case 1: return '#fdb88c'
        case 2: return '#6edfc7'
        case 3: return '#5f89f4'
        case 4: return '#ef83ae' //--
        default:
            break;
    }
}