import { Layout } from 'antd'
import React, { useContext, useEffect } from 'react'
import { FooterApp } from '../Layouts/home/FooterApp'
import { HeaderApp } from '../Layouts/home/HeaderApp'
import { ContentShipments } from '../Layouts/shipments/ContentShipments'
import { t } from 'i18next'
import { appContext } from '../context/AuthContext'
import { renew_jwt } from '../helpers/renew_jwt'
import useAxios from '../Hooks/UseAxios'



export const ShipmentsPage = () => {

  const { theme, logout } = useContext(appContext)
  const { response, loading, error, operation } = useAxios()

  useEffect(() => {
    renew_jwt(logout, operation)
  }, [])

  useEffect(() => {
    const changeBody = () => {
      // document.body.style.backgroundColor = `${theme ? '#fff' : '#2b2d31'}`;
      document.body.style.background = `linear-gradient(to bottom, ${theme ? '#FFF' : '#2b2d31'} , ${theme ? '#FAFAFA' : '#2b2d31'} )`
    }
    changeBody()
  }, [theme])

  useEffect(() => {
    if (response) {
      console.log(response.data.data.token)
      localStorage.setItem("token", response.data.data.token)
    }
  }, [response])


  return (
    <Layout style={{ backgroundColor: theme ? '#ffffff80' : 'transparent' }}
    //  className="background-app"
    >
      {/* <HeaderApp text={<p>{t('Ship.title')}</p>} module={'shipments'} /> */}
      <HeaderApp text={<p><b>Meta</b>Map</p>} module={'shipments'} />
      <ContentShipments />
      <FooterApp />
    </Layout>

  )
}
