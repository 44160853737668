import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import useAxios from '../Hooks/UseAxios'

export const SubTable = (props) => {

    const { expandedColumns, theme, _id } = props
    const { response, loading, error, operation } = useAxios()
    const [data, setData] = useState([])


    async function getDetails() {
        const token = localStorage.getItem("token");
        try {
            await operation({
                method: "POST",
                url: "/LatestDetails/LatestDetails",
                headers: {
                    accept: "*/*",
                    token: token
                },
                data: {
                    _id: _id
                },
            })

        } catch (error) {
            console.error(error)
        }

    }

    useEffect(() => {
        if (!loading) {
            switch (response.data.msg) {

                case "Details ready":
                    if (response.data.Data) {
                        const values = response.data.Data
                        setData([])
                        // console.log('Details', values)

                        values.map((doc) => {
                            // console.log(doc)
                            const newDocument = {
                                key: doc._id,
                                Difficulty: doc.Difficulty,
                                IdentityId: doc.Identity_Id,
                                TrainingDocumentId: doc.Training_Document_Id,
                                Front: doc.Front[0],
                                Back: doc.Back[0],
                                Reason: doc.Reason,
                                Status: doc.Status,
                            }
                            // console.log(newDocument)
                            setData((prevData) => [...prevData, newDocument]);
                        })
                    }


                default:
                    break;
            }
            // setTimeout(() => {
            //     setTableReady(true)
            // }, 1000);
        }
    }, [response])


    useEffect(() => {
        getDetails()
    }, [])


    return (
        <Table
            columns={expandedColumns}
            dataSource={data}
            pagination={false}
            className={`${theme ? 'sub-table' : 'my-table-2-'}`}
            scroll={{
                y: 'auto',
                // x: '240vh',
                x: '180vh'
            }} />
    )
}
