import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Empty, FloatButton, Form, InputNumber, Layout, Radio, Row, Select, Switch, Table, Tag, message } from 'antd';
import useAxios from '../../Hooks/UseAxios';
import { getAmountPerDifficulty, getCountries, getDifficulties, getDocTests, getDocsByCountry, getDocuments, getStates, getTrainingDocsById, postData } from '../../services/apiServices';
import { logos } from '../../helpers/logo';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { colors } from '../../helpers/colors';
import { SpecificCard } from '../../components/SpecificCard';
import { appContext } from '../../context/AuthContext';
import { BiRefresh } from "react-icons/bi";
import TimerApp from '../../components/Timer';
import { FaHandPointUp } from "react-icons/fa";
import { IoMdSend } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';


const { Content } = Layout;
const { Option } = Select

export const ContentShipments = (props) => {
    const { user, previousConfigs, handleConfigs, theme, logout } = useContext(appContext)

    const { t } = useTranslation();
    const [form2] = Form.useForm();
    const navigate = useNavigate()

    const [render, setRender] = useState(false)
    const [testCategory, setTestCategory] = useState('')
    const [country, setCountry] = useState(null)
    const [document, setDocument] = useState(null)
    const [selectAll, setSelectAll] = useState(false)
    const [selectSpecific, setSelectSpecific] = useState(false)
    const [isDocCountry, setIsDocCountry] = useState(false)
    const [countryState, setCountryState] = useState(false)
    const [documentState, setDocumentState] = useState(false)
    const [difficulties, setDifficulties] = useState([])
    const [docsBy, setDocsBy] = useState([])
    const [DocsCountryEneable, setDocsCountryEneable] = useState(false)
    const [stateState, setStateState] = useState(false)
    const [CA, setCA] = useState(false)
    const [US, setUS] = useState(false)
    const [state, setState] = useState('NA')
    const [data, setData] = useState([])
    const [specificData, setSpecificData] = useState([])
    const [amountValues, setAmountValues] = useState()
    const [selectAmount, setSelectAmount] = useState([])
    const [tranningReady, setTranningReady] = useState(false)
    const [difficutly, setDifficutly] = useState([])
    const [eneable, setEneable] = useState(true)
    const [sending, setSending] = useState(false)
    const [typeOf, setTypeOf] = useState('RANDOM')
    const [typeOfState, setTypeOfState] = useState(false)
    const [specificDocs, setSpecificDocs] = useState([])
    const [curentPage, setCurentPage] = useState(1)

    const [allCountries, setAllCountries] = useState([])
    const [allDocuments, setAllDocuments] = useState([])
    const [allDocTests, setAllDocTests] = useState([])
    const [allStatesUS, setAllStatesUS] = useState([])
    const [allStatesCA, setAllStatesCA] = useState([])
    const [AllDifficulties, setAllDifficulties] = useState([])
    const [allDocsBy, setallDocsBy] = useState([])
    const [expectedTime, setExpectedTime] = useState(null)
    const [onHover, setOnHover] = useState(false)


    const { response, loading, error, operation } = useAxios()

    const columns = [
        {
            title: `${t('Random.setConfig.difficulty')}`,
            dataIndex: 'difficulty',
            key: 'difficulty',
            render: (text, record) => <p style={{ wordWrap: 'break-word', textAlign: 'center', fontWeight: 500, color: `${record.available === 0 ? '#8cadff' : ''}` }}>{text}</p>
            // width: '8%'
        },
        {
            title: `${t('Random.setConfig.available')}`,
            dataIndex: 'available',
            key: 'available',
            render: (text) => <p style={{ wordWrap: 'break-word', width: '100%', textAlign: 'center', color: `${text === 0 ? '#8cadff' : ''}` }}>{`${text === 0 ? 'No tests available' : text}`}</p>


        },
        {
            title: `${t('Random.setConfig.InputAmount')}`,
            dataIndex: 'inputNumber',
            key: 'inputNumber',
            render: (text, record) => (
                <>
                    {
                        record.available !== 0
                            ?
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Form.Item
                                    name={`${record.difficulty}`}
                                    initialValue={0}
                                    normalize={(value) => value || 0}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <InputNumber
                                        style={{ marginBottom: '-4vh' }}
                                        className={`${theme ? '' : 'input-amount-dark'}`}
                                        defaultValue={0}
                                        min={0}
                                        // value={(value) => handleInputChange(value, record.key)}
                                        max={record.available}
                                        onChange={(value) => handleInputChange(value, record.key)}
                                    />
                                </Form.Item>
                            </div>

                            : <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* <p style={{ wordWrap: 'break-word', width: '50%', fontStyle:'italic' }}>No tests available</p> */}
                            </div>

                    }

                </>


            ),
        },
    ]

    const handleTypeOf = (e) => {
        if (e) setTypeOf('SPECIFIC')
        else setTypeOf('RANDOM')
        setDifficulties([])
    }

    const handleDocument = (e) => {
        setDocsCountryEneable(false)
        setDocumentState(true)
        setEneable(true)
        setDocument(e)
        setSelectAll(false)
        setSelectSpecific(false)
        setIsDocCountry(false)
        setDifficulties([])
        setDifficutly([])
        setDocsBy([])
        handleDifficulties(null)
        setSpecificDocs([])
    }

    const handleCountry = (e) => {
        setCountry(e)
        setCountryState(true)
        setSelectAll(false)
        setSelectSpecific(false)
        setDocsCountryEneable(false)
        setIsDocCountry(false)
        setEneable(true)
        setDifficulties([])
        setDocsBy([])
        setDifficutly([])
        handleDifficulties(null)
        setSpecificDocs([])
        // setSelectedOptions([])

        if (e === 'US') {
            setStateState(true)
            setUS(true)
            setCA(false)
        }
        else if (e === 'CA') {
            setStateState(true)
            setUS(false)
            setCA(true)
        }
        else {
            setStateState(false)
            setUS(false)
            setCA(false)
            setState('NA')
        }
    }

    const handleDifficulties = (e) => {

        if (e) {
            if (countryState & documentState) {
                if (selectAll || selectSpecific) {
                    setDifficulties(e)
                    // message.info(`Realizando busqueda`)
                    console.log(e)
                    getAmountPerDifficulty(operation, typeOf, country, document, docsBy, testCategory, state, e)
                }

                // else {
                //     message.error(`${t('Shipments.Warn')}`)
                // }
            }
        }

    }

    const handleDocByCountryState = () => {
        setSelectAll(!selectAll)
        setSelectSpecific(!selectSpecific)
        if (selectSpecific) setDocsBy([])
        else if (selectAll) setDocsBy(JSON.parse(localStorage.getItem('documents_by_country')))
        setDifficulties([])
        handleDifficulties([])
        setDifficutly([])

    }

    const handleSelect = (all, specific) => {
        if (countryState & documentState) {
            setSelectAll(all)
            setSelectSpecific(specific)
            getDocumentsBy()
        }

        else {
            message.error(`${t('Random.SetConfig.warning.buttons')}`)
        }
    }

    const getDocumentsBy = async () => {
        await getDocsByCountry(operation)
    }

    const handleInputChange = (value, key) => {

        const updatedData = selectAmount.map((record) => {
            if (record.key === key) {
                return { ...record, inputNumber: value };
            }
            return record;
        });
        setSelectAmount(updatedData);
        form2.submit()

        return value

    };

    const onFinish2 = (values) => {
        console.log(values);
        console.log(amountValues)
        setEneable(false)

        // Recorre el arreglo de objetos
        const resultadoFinal = amountValues.map((objeto) => {
            const { Difficulty } = objeto;

            // Verifica si hay un valor en el formulario que coincida con la dificultad actual
            if (values[Difficulty] !== undefined) {
                // Actualiza el campo "Documents" con el valor correspondiente del formulario
                objeto.Documents = values[Difficulty].toString();
            }

            return objeto;
        });

        console.log(resultadoFinal)
        setDifficutly(resultadoFinal)

    }

    const addPreviousConfig = (newConfig) => {
        // Verifica si ya existe un objeto con los mismos valores
        if (previousConfigs) {
            const objetoExistente = previousConfigs.find((obj) => (
                obj.country === newConfig.country &&
                obj.document === newConfig.document &&
                obj.difficulties === newConfig.difficulties
            ));

            if (objetoExistente) {
                return;
            }

            if (previousConfigs.length >= 7) previousConfigs.shift();
            handleConfigs([newConfig, ...previousConfigs]);
        }

        else handleConfigs([newConfig])
    }

    const totalSpecifics = (specificData) => {
        let totalTests = 0
        specificData.map((item => {
            totalTests = totalTests + parseInt(item.quantity)
        }))
        return totalTests
    }

    const sendData = async () => {

        const newPreviousConfig = {
            typeOf: typeOf,
            testCategory: testCategory,
            country: country,
            state: state,
            document: document,
            docsBy: docsBy,
            difficutly: difficutly,
            amountValues: typeOf === 'RANDOM' ? amountValues : specificData.flatMap(({ _id, quantity }) =>
                Array(quantity).fill(_id)
            ),
            difficulties: difficulties,
            selectAll: selectAll,
            selectSpecific: selectSpecific,
            color: colors(document === 'NI' ? 1 : document === 'DL' ? 3 : document === 'PS' ? 4 : 2)
        }

        const variable = typeOf === 'RANDOM' ? amountValues : specificData.flatMap(({ _id, quantity }) =>
            Array(quantity).fill(_id)
        );
        let totalTests = 0

        if (typeOf === 'RANDOM') {
            difficutly.map((item => {
                totalTests = totalTests + parseInt(item.Documents)
            }))
            setExpectedTime(transofrmTimer(totalTests))
            addPreviousConfig(newPreviousConfig)
        } else {
            setExpectedTime(transofrmTimer((specificData.flatMap(({ _id, quantity }) =>
                Array(quantity).fill(_id)).length)))
        }

        console.log('var: ', variable)

        setRender(false)
        setSending(true)
        message.info(`${t('Shipments.sending')}`)
        await postData(operation, typeOf, country, document, docsBy, variable, testCategory, state)
    }

    const transofrmTimer = (tests) => {

        const time = tests * 25
        console.log(time)
        // Calcular minutos y segundos
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

        // Formatear el resultado
        const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        return formattedTime;
    };

    const handlePreviousConfig = (_, testCategory, document, country, docsBy, difficulties, amountValues, selectAll, selectSpecific) => {
        setDocumentState(true)
        setCountryState(true)
        setTypeOf(typeOf)
        setTestCategory(testCategory)
        setDocument(document)
        setCountry(country)
        setDocsBy(docsBy)
        setSelectAll(selectAll)
        setSelectSpecific(selectSpecific)
        setDifficulties(difficulties)
        setIsDocCountry(true)
        setDifficutly(amountValues)

        if (typeOf === 'RANDOM') {
            setAmountValues(amountValues)
        } else setSpecificData(amountValues)

        handleDifficulties(difficulties)

    }


    const trainingDocById = (_id) => {

        try {
            getTrainingDocsById(operation, _id)
        } catch (error) {
            message.error(`${t('Home.Search.Message-2')}`)
        }

    }

    const resetIDE = () => {
        setTestCategory('')
        setDocument('')
        setCountry('')
        setDocsBy([])
        setSelectAll(false)
        setSelectSpecific(false)
        setDifficulties([])
        setIsDocCountry(false)
        setDifficutly([])
        setAmountValues([])
        setSpecificDocs([])
        setEneable(true)
        setCurentPage(1)
    }



    useEffect(() => {

        const getData = async () => {
            try {
                await Promise.all([
                    getCountries(operation),
                    getDocuments(operation),
                    getDocTests(operation),
                    getStates(operation),
                    getDifficulties(operation),
                ]);
            } catch { }
        }
        getData()
    }, []);

    useEffect(() => {
        if (AllDifficulties.length === 0) {
            getDifficulties(operation);
        } else if (allStatesCA.length === 0 || allStatesUS.length === 0) {
            getStates(operation);
        } else if (allDocuments.length === 0) {
            getDocuments(operation);
        } else if (allCountries.length === 0) {
            getCountries(operation);
        } else if (allDocTests.length === 0) {
            getDocTests(operation);
        }
    }, [AllDifficulties, allStatesCA, allStatesUS, allDocuments, allCountries, allDocTests]);


    useEffect(() => {
        console.log(expectedTime)
    }, [expectedTime])

    useEffect(() => {


        const increaseObjects = () => {
            console.log(amountValues)

            const difficulties = amountValues.map(item => item.Difficulty);
            const totalDocs = amountValues.map(item => item.Total_Docs);

            const dataSource = difficulties.map((difficulty, index) => ({
                key: index,
                difficulty,
                available: totalDocs[index]
            }))

            setData(dataSource)
        }

        if (amountValues) increaseObjects()

    }, [amountValues])

    useEffect(() => {

        if (setSpecificData.length <= 0) setEneable(true)
        else setEneable(false)

    }, [specificData])

    useEffect(() => {
        if (typeOf === 'RANDOM') setTypeOfState(false)
        else setTypeOfState(true)
    }, [typeOf])

    useEffect(() => {
        if (!loading) {
            switch (response.data.msg) {
                case "Get all countries":
                    const count = response.data.data;
                    const countryNames = count.map(country => country.Code_Country)
                    setAllCountries(countryNames.sort())
                    // localStorage.setItem('countries', JSON.stringify(countryNames.sort()))
                    break;

                case "Get all documents":
                    const documents = response.data.data;
                    const documentsName = documents.map(document => document.Type_Document);
                    setAllDocuments(documentsName.sort())
                    // localStorage.setItem('documents', JSON.stringify(documentsName.sort()))
                    break;

                case "Get all Docs by Country":
                    const allDocsByCountry = response.data.data;
                    const docsBycountry = allDocsByCountry.filter(doc => doc.Code_Country === country)
                    const filterDcosBy = docsBycountry.filter(docs => docs.Type_Document === document)

                    const documentsByCountry = filterDcosBy.map(doc => doc.Name_Document);
                    setallDocsBy(documentsByCountry.sort())
                    // localStorage.setItem('documents_by_country', JSON.stringify(documentsByCountry.sort()))
                    setDocsCountryEneable(true)
                    if (selectAll) {
                        setDocsBy(documentsByCountry.sort())
                    }
                    setTimeout(() => {
                        setIsDocCountry(true)
                    }, 1000);
                    break;

                case "Get all States":
                    const states = response.data.data;
                    const CAStates = states.filter(state => state.Code_Country === 'CA')
                    const USStates = states.filter(state => state.Code_Country === 'US')
                    const CAstatesName = CAStates.map(state => state.Code_State);
                    const USstatesName = USStates.map(state => state.Code_State);
                    setAllStatesCA(CAstatesName.sort())
                    setAllStatesUS(USstatesName.sort())
                    // localStorage.setItem('statesCA', JSON.stringify(CAstatesName.sort()))
                    // localStorage.setItem('statesUS', JSON.stringify(USstatesName.sort()))

                    break;

                case "Get all DocsTest":
                    const docTests = response.data.data;
                    const AlltypeDoc = docTests.filter(doc => doc.Name_Doc_Test !== "TRAINER DOCUMENT")
                    const AlltypeDoc2 = AlltypeDoc.filter(doc => doc.Name_Doc_Test !== "AGENT DOCUMENT")
                    const TypeDocTest = AlltypeDoc2.map(doc => doc.Name_Doc_Test)
                    setAllDocTests(TypeDocTest.sort().reverse())
                    break
                // localStorage.setItem('type_doc_test', JSON.stringify(TypeDocTest.sort().reverse()))

                case "Get all difficulties":
                    const difficulties = response.data.data;
                    const filterDifficulties = difficulties.filter(doc => doc.Short_Name !== "E" & doc.Short_Name !== "M" & doc.Short_Name !== "N")
                    const AllDifficulties = filterDifficulties.map(difficulty => difficulty.Short_Name);
                    setAllDifficulties(AllDifficulties.sort())
                    break
                // localStorage.setItem('difficulties', JSON.stringify(AllDifficulties.sort()))

                case "Specific Documents":

                    if (response.data.data) {
                        const documents = response.data.data
                        console.log(documents)
                        setCurentPage(1)
                        setSpecificDocs([])
                        documents.forEach((doc) => {
                            if (doc.ArrayDocs && Array.isArray(doc.ArrayDocs)) {
                                doc.ArrayDocs.forEach((item) => {
                                    console.log(item)
                                    setSpecificDocs((prevData) => [...prevData, item])
                                })
                            }
                        })
                    }

                    break

                case "Training Documents":


                    const data = response.data.data.Doc_Difficulties
                    if (data) setAmountValues(data)

                    break;

                case "Training Documents by Id":
                    console.log(response.data.data)
                    const test = response.data.data
                    setSpecificDocs([])
                    test.map((doc) => {

                        const chosenDoc = {
                            Back: doc.Back,
                            Difficulty: doc.Difficulty,
                            Front: doc.Front,
                            Name_Doc: doc.Name_Doc,
                            chosen: false,
                            quantity: 0,
                            _id: doc._id
                        }

                        setSpecificDocs([chosenDoc])
                        handlePreviousConfig('SPECIFIC', 'TESTER DOCUMENT', doc.Type_Doc, doc.Country, [doc.Name_Doc], [doc.Difficulty], amountValues, false, true)


                    })

                    break

                case "OK":
                    setSending(false)
                    message.success(`${t('Shipments.Success.message')}`)
                    resetIDE()

                    break;

                case "Token no válido":
                    message.error(`${t('Token.Message')}`)
                    logout()

                default:
                    break;
            }
            setTimeout(() => {
                setRender(true)
                setTranningReady(true)
            }, 1000);
        }
    }, [response])


    return (
        <Content
            className={sending ? 'scrollable-div background-app' : 'scrollable-div'}
            style={{
                marginTop: '11vh', // Ajusta este valor para que el contenido no quede detrás del header
                // marginBottom: '2vh', // Ajusta este valor para que el contenido no quede detrás del footer
                overflowY: 'scroll',
                height: 'auto', // Ajusta estos valores según el header y footer
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                flexDirection: 'row',
                // padding: '0%',
                backgroundColor: 'transparent'
            }}
        >
            {
                render ?
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between',
                        flexDirection: 'column', width: '100%',
                    }}>
                        <Row style={{
                            display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between',
                            flexDirection: 'row', width: '100%', height: 'auto'
                        }}>
                            <div style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                width: typeOf === 'RANDOM' ? '75%' : '100%', height: 'auto',
                                // borderRight:'1.5px solid #00000060',
                                // backgroundColor: `${theme ? '#ffffff80' : '#27282c80'}`, borderRadius: '1.5vh', border: '1.5px solid #00000080',
                                padding: '1%',
                            }}>
                                <Row style={{
                                    width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                }}>
                                    <p style={{
                                        fontWeight: 600, fontSize: '1.2em', color: `${theme ? '' : '#AAA'}`
                                    }}>{t('Random.setConfig')}</p>

                                    <Row style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                    }}>
                                        <Switch
                                            onChange={handleTypeOf}
                                            checked={typeOfState}
                                            style={{ backgroundColor: `${typeOfState ? theme ? '#a8c1f480' : '#393b3f' : theme ? '#d5d5d5' : '#393b3f'}`, color: '#000' }}
                                            // checked={false}
                                            checkedChildren={'Manual'}
                                            unCheckedChildren={'Random'}
                                        />
                                        {/* <Button
                                            type='ghost'
                                            icon={<BiRefresh size={20} style={{ color: '#68769180' }} />}
                                            onClick={getInitialData} /> */}

                                    </Row>


                                </Row>

                                <Col style={{
                                    display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '95%'
                                }}>
                                    <p style={{
                                        fontWeight: 600, fontSize: '1em', color: `${theme ? '#00000050' : '#AAAAAA50'}`
                                    }}>{t('Ship.PreviousConfigs')}</p>
                                    <Row style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                        width: '100%'
                                    }}>
                                        {
                                            previousConfigs ?
                                                previousConfigs.map((configs) => (
                                                    <Tag
                                                        onMouseEnter={() => setOnHover(!onHover)}
                                                        className={onHover ? 'prev' : 'prev_'}
                                                        onClick={() => handlePreviousConfig(configs.typeOf, configs.testCategory, configs.document, configs.country, configs.docsBy, configs.difficulties, configs.amountValues, configs.selectAll, configs.selectSpecific)}
                                                        type='primary'
                                                        style={{
                                                            marginRight: '10px',
                                                            backgroundColor: theme ? `${configs.color}60` : `${configs.color}40`,
                                                            border: theme ? `0px solid ${configs.color}60` : `0px solid ${configs.color}40`,
                                                            fontWeight: 600, color: theme ? '#fff' : `${configs.color}80`,
                                                            width: typeOf === 'RANDOM' ? '15%' : '10%', height: '3vh', fontSize: '1.1em', cursor: 'pointer',
                                                            borderRadius: '1vh',
                                                            // boxShadow: '0px 0px 8px rgba(0,0,0,0.05)',
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                        }}>{configs.country}-{configs.document}-{configs.difficulties}</Tag>
                                                ))

                                                : <Button
                                                    // onClick={() => handlePreviousConfig(configs.testCategory, configs.document, configs.country, configs.docsBy, configs.difficulty, configs.amountValues)}
                                                    // type='primary'
                                                    style={{
                                                        marginRight: '10px', backgroundColor: '#AAAAAA10', border: theme ? '1px solid #AAAAAA30' : '0px solid #000',
                                                        color: theme ? '' : '#aaaaaa80', borderRadius: '3vh'
                                                    }}>{t('Shipments.prevconfigs')}</Button>
                                        }


                                    </Row>
                                </Col>

                                <hr style={{ width: '95%', border: `1px solid ${theme ? '#00000020' : '#AAAAAA20'}`, borderRadius: '2vh', margin: '3vh 0 1vh 0' }} />

                                <div style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: typeOf === 'RANDOM' ? 'column' : 'row',
                                    width: typeOf === 'RANDOM' ? '100%' : '95%'
                                }}>
                                    <Row style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                        width: typeOf === 'RANDOM' ? '95%' : '59%',
                                    }}>
                                        <Col style={{
                                            width: '50%'
                                        }}>
                                            <p style={{ color: `${theme ? '#00000090' : '#AAAAAA80'}`, fontWeight: 400 }}>{t('Random.setConfig.typeTest')}</p>
                                            {
                                                allDocTests ?
                                                    <Radio.Group
                                                        onChange={(e) => setTestCategory(e.target.value)}
                                                        value={testCategory}
                                                        style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', }}>
                                                        {

                                                            allDocTests.map((docs) => (
                                                                <Radio.Button value={docs} style={{
                                                                    width: '50%',
                                                                    borderRadius: docs === 'TESTER DOCUMENT' ? '3vh 0 0 3vh' : '0 3vh 3vh 0',
                                                                    color: `${testCategory === docs ? theme ? '#333333' : '#aaa' : theme ? '#c8c8c8' : '#aaaaaa40'}`,
                                                                    backgroundColor: `${testCategory === docs ? theme ? '#a8c1f480' : '#28292C' : theme ? '#fff' : '#2b2d31'}`,
                                                                    border: `${testCategory === docs ? '0.5px solid #00000040' : theme ? '' : '1px solid #00000060'}`,
                                                                    zIndex: 0

                                                                }}>
                                                                    {docs.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
                                                                </Radio.Button>

                                                            ))
                                                        }
                                                    </Radio.Group>
                                                    : <></>
                                            }
                                        </Col>

                                        <Row style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                            width: '24%', marginLeft: '1%'
                                        }}>

                                            <Col style={{
                                                width: stateState ? '49%' : '100%',
                                            }}>
                                                <p style={{ color: '#00000090', fontWeight: 400, color: `${theme ? '#00000090' : '#AAAAAA80'}` }}>{t('Random.setConfig.country')}</p>
                                                {
                                                    allCountries ?
                                                        <Select
                                                            placeholder="MX"
                                                            onChange={handleCountry}
                                                            value={country}
                                                            className={`${theme ? 'random-light' : 'random-dark'}`}
                                                            style={{ width: '100%', }}
                                                            dropdownStyle={{
                                                                backgroundColor: theme ? '' : '#CCD5DC'
                                                            }}
                                                            // dropdownStyle={{backgroundColor: '#333437', color: '#496177'}}
                                                            popupClassName={`${theme ? '' : 'custom-dropdown'}`}

                                                        >

                                                            {

                                                                allCountries.map((country) => (
                                                                    <Option key={country} value={country}>
                                                                        {country}
                                                                    </Option>
                                                                ))
                                                            }

                                                        </Select>
                                                        : <></>
                                                }

                                            </Col>

                                            <Col style={{
                                                display: stateState ? '' : 'none', width: '49%',
                                            }}>
                                                <p style={{ color: '#00000090', fontWeight: 400, color: `${theme ? '#00000090' : '#AAAAAA80'}` }}>{t('Random.setConfig.state')}</p>

                                                {
                                                    US ?
                                                        <Select
                                                            onChange={(e) => setState(e)}
                                                            value={state}
                                                            className={`${theme ? 'random-light' : 'random-dark'}`}
                                                            popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                                                            dropdownStyle={{
                                                                backgroundColor: theme ? '' : '#CCD5DC'
                                                            }}
                                                            style={{ width: '100%' }} >
                                                            {
                                                                allStatesUS.map((state) => (
                                                                    <Option key={state} value={state}>
                                                                        {state}
                                                                    </Option>
                                                                ))
                                                            }

                                                        </Select>
                                                        :
                                                        <Select
                                                            onChange={(e) => setState(e)}
                                                            value={state}
                                                            className={`${theme ? 'random-light' : 'random-dark'}`}
                                                            popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                                                            dropdownStyle={{
                                                                backgroundColor: theme ? '' : '#CCD5DC'
                                                            }}
                                                            style={{ width: '100%' }} >
                                                            {
                                                                allStatesCA.map((state) => (
                                                                    <Option key={state} value={state}>
                                                                        {state}
                                                                    </Option>
                                                                ))
                                                            }

                                                        </Select>
                                                }
                                            </Col>

                                        </Row>

                                        <Col style={{
                                            width: '24%', marginLeft: '1%'
                                        }}>
                                            <p style={{ color: '#00000090', fontWeight: 400, color: `${theme ? '#00000090' : '#AAAAAA80'}` }}>{t('Random.setConfig.document')}</p>
                                            {
                                                allDocuments ?
                                                    <Select placeholder="NI" onChange={handleDocument}
                                                        className={`${theme ? 'random-light' : 'random-dark'}`}
                                                        value={document}
                                                        dropdownStyle={{
                                                            backgroundColor: theme ? '' : '#CCD5DC'
                                                        }}
                                                        popupClassName={`${theme ? '' : 'custom-dropdown'}`}
                                                        style={{ width: '100%' }}>
                                                        {
                                                            allDocuments.map((doc) => (
                                                                <Option key={doc} value={doc}>
                                                                    {doc}
                                                                </Option>
                                                            ))
                                                        }

                                                    </Select>
                                                    : <></>
                                            }
                                        </Col>
                                    </Row>

                                    <Row style={{
                                        display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row',
                                        width: typeOf === 'RANDOM' ? '95%' : '40%',
                                        // marginLeft: typeOf === 'RANDOM' ? '0px' : '5px'
                                    }}>
                                        <Col style={{
                                            width: '50%'
                                        }}>
                                            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                <p style={{ color: '#00000090', fontWeight: 400, color: `${theme ? '#00000090' : '#AAAAAA80'}` }}>{t('Random.setConfig.docCountry')}</p>
                                                <Switch initialValue={false}
                                                    size='small'
                                                    style={{ display: `${selectAll || selectSpecific ? '' : 'none'}`, marginLeft: '2vh', backgroundColor: `${selectSpecific ? theme ? '#a8c1f480' : '#AAA' : theme ? '#d5d5d5' : '#404144'}` }}
                                                    onChange={handleDocByCountryState}
                                                    // checkedChildren={t('Random.setConfig.docSwitch')} unCheckedChildren={t('Random.setConfig.docSwitch')}
                                                    checked={selectSpecific} />
                                            </Row>

                                            <div style={{ width: '100%', display: `${selectSpecific ? 'none' : 'flex'}`, flexDirection: 'row', justifyContent: 'center', }}>
                                                <Button onClick={() => handleSelect(true, false)}
                                                    style={{
                                                        // marginBottom: '-5vh',
                                                        width: `${selectAll ? '100%' : '50%'}`, backgroundColor: `${selectAll ? theme ? '#a8c1f480' : '#28292C' : theme ? '' : '#2b2d31'}`, cursor: `${selectAll ? 'not-allowed' : ''}`,
                                                        borderRadius: selectAll ? '3vh' : '3vh 0 0 3vh',
                                                        border: `${selectAll ? '0.5px solid #00000040' : theme ? '' : '1px solid #00000060'}`,
                                                        color: `${selectAll ? theme ? '#000' : '#aaa' : theme ? '#aaa' : '#aaaaaa80'}`, display: `${selectAll ? isDocCountry ? '' : 'none' : ''}`, wordWrap: 'break-word',
                                                    }}
                                                >{`${selectAll ? `${t('Random.setConfig.docCountry_All-B')}` : `${t('Random.setConfig.docCountry_All')}`}`}</Button>
                                                <Button onClick={() => handleSelect(false, true)}
                                                    style={{
                                                        display: `${selectAll ? 'none' : ''}`, backgroundColor: `${theme ? '' : '#2b2d31'}`,
                                                        borderRadius: '0 3vh 3vh 0',
                                                        borderTop: theme ? '' : '1px solid #00000060',
                                                        borderRight: theme ? '' : '1px solid #00000060',
                                                        borderBottom: theme ? '' : '1px solid #00000060',
                                                        borderLeft: theme ? '0px solid #000' : '1px solid #00000060',
                                                        width: '50%', color: `${theme ? '#aaa' : '#aaaaaa80'}`
                                                    }}>
                                                    {t('Random.setConfig.docCountry_Specific')}
                                                </Button>
                                            </div>

                                            {

                                                isDocCountry
                                                    ? <Select
                                                        mode='multiple'
                                                        onChange={(e) => setDocsBy(e)}
                                                        value={docsBy}
                                                        placeholder={`${selectSpecific ? 'Please select a document' : 'null'}`}
                                                        className={`${theme ? 'random-light' : 'random-dark'}`}
                                                        dropdownStyle={{
                                                            backgroundColor: theme ? '' : '#CCD5DC'
                                                        }}
                                                        popupClassName={`${theme ? '' : 'custom-dropdown'}`}

                                                        style={{ width: '100%', display: `${selectSpecific ? '' : 'none'}` }}>
                                                        {
                                                            allDocsBy.map((grad) => (
                                                                <Option key={grad} value={grad}>
                                                                    {grad}
                                                                </Option>
                                                            ))
                                                        }

                                                    </Select>

                                                    : !selectAll && !selectSpecific
                                                        ? <></>
                                                        : <div style={{
                                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                            height: '1vh'
                                                        }}>

                                                            <img src={logos.rainbow} style={{
                                                                width: '15%',
                                                            }} />
                                                        </div>
                                            }


                                        </Col>

                                        <Col style={{
                                            width: '49%',
                                        }}>
                                            <p style={{ color: '#00000090', fontWeight: 400, color: `${theme ? '#00000090' : '#AAAAAA80'}` }}>{t('Random.confirmData.difficulties')}</p>
                                            {
                                                AllDifficulties ?
                                                    <Select
                                                        mode='multiple'
                                                        onChange={handleDifficulties}
                                                        value={difficulties}
                                                        placeholder={'A'}
                                                        dropdownStyle={{
                                                            backgroundColor: theme ? '' : '#AAA'
                                                        }}
                                                        className={`${theme ? 'random-light' : 'random-dark'}`}
                                                        popupClassName={`${theme ? '' : 'custom-dropdown'}`}

                                                        style={{ width: '100%', }}>
                                                        {
                                                            AllDifficulties.map((grad) => (
                                                                grad != null ?
                                                                    <Option key={grad} value={grad}>
                                                                        {grad}
                                                                    </Option>
                                                                    : <></>
                                                            ))
                                                        }

                                                    </Select>
                                                    : <></>
                                            }
                                        </Col>

                                    </Row>

                                </div>



                                {
                                    typeOf === 'RANDOM'
                                        ? <Form
                                            onFinish={onFinish2}
                                            form={form2}
                                            style={{
                                                // display: `${formCompleted ? '' : 'none'}`,
                                                width: '95%', margin: '3vh 0 2vh 0',
                                                height: 'auto', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
                                            }}>

                                            {
                                                tranningReady ?
                                                    <Table className={`${theme ? 'light-table' : 'my-table-revship'}`}
                                                        style={{ width: '100%', height: 'auto', borderRadius: '0' }}
                                                        columns={columns} dataSource={data}
                                                        pagination={false} />
                                                    :
                                                    <div style={{
                                                        width: '100%', height: 'auto',
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                    }}>
                                                        <img src={logos.rainbow} style={{
                                                            width: '12%',
                                                        }} />
                                                    </div>
                                            }

                                        </Form>
                                        :
                                        <>

                                        </>
                                }

                            </div>

                            {
                                typeOf === 'RANDOM' ?
                                    <div
                                        className='scrollable-div'
                                        style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                            padding: '1%', width: '25%', height: typeOf === 'RANDOM' ? '100vh' : '45vh', backgroundColor: `${theme ? '#fafafa' : '#28292C'}`,
                                            overflowY: 'scroll',
                                        }}>
                                        <Row style={{
                                            width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                            flexDirection: 'row'
                                        }}>
                                            <p style={{
                                                fontWeight: 600, fontSize: '1.2em', width: '40%', textAlign: 'left', color: `${theme ? '' : '#AAA'}`
                                            }}>{t('Ship.Summary')}</p>

                                            <Row style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                                width: '59%'
                                            }}>
                                                <Button
                                                    onClick={sendData}
                                                    // disabled={eneable}
                                                    icon={<IoMdSend size={17} style={{ marginRight: '8px', color: theme ? '#00000070' : '#aaa', }} />}
                                                    style={{
                                                        backgroundColor: theme ? '#a8c1f480' : '#393B3F',
                                                        border: '0px solid #00000060', color: theme ? '#00000090' : '#aaa',
                                                        fontWeight: 600,
                                                        borderRadius: '3vh',
                                                        marginRight: '8px',
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                        // borderRadius: '1vh 3vh 3vh 1vh',
                                                        // boxShadow: '0px 0px 5px rgba(0,0,0,0.3)'
                                                    }}
                                                >{t('Ship.Send')}</Button>
                                                <Button
                                                    onClick={resetIDE}
                                                    icon={<MdDeleteForever size={17} style={{ color: theme ? '#00000070' : '#aaa', }} />}
                                                    style={{
                                                        backgroundColor: theme ? '#a8c1f480' : '#393B3F',
                                                        border: '0px solid #00000060',
                                                        color: theme ? '' : '#00000070',
                                                        // margin: '0 0.5vh 0 0.5vh',
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        borderRadius: '50%'
                                                    }} />
                                            </Row>

                                        </Row>



                                        {
                                            testCategory !== '' ?
                                                <div style={{
                                                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                    flexDirection: 'column', width: '100%', padding: '0 3% 0 3%'
                                                }}>
                                                    <Col style={{
                                                        width: '95%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'
                                                    }}>
                                                        <p style={{
                                                            width: '100%', textAlign: 'left', margin: '1vh 0 1vh 0',
                                                            color: `${theme ? '#00000090' : '#AAA'}`, fontWeight: 500
                                                        }}>{t('Ship.Configuration')}</p>
                                                        <p style={{
                                                            fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                            color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em'
                                                        }}>{testCategory.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}</p>
                                                        <p style={{
                                                            fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                            color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em'
                                                        }}>{country}</p>
                                                        <p style={{
                                                            fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                            color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em', display: stateState ? '' : 'none'
                                                        }}>{state}</p>
                                                        <p style={{
                                                            fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                            color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em'
                                                        }}>{document}</p>

                                                    </Col>

                                                    <hr style={{
                                                        width: '95%', border: `1px solid ${theme ? '#00000030' : '#AAAAAA70'}`, margin: '2vh 0 2vh 0'
                                                    }} />

                                                    <Col style={{
                                                        width: '95%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'
                                                    }}>
                                                        <p style={{
                                                            width: '100%', textAlign: 'left', margin: '1vh 0 1vh 0',
                                                            color: `${theme ? '#00000090' : '#AAAAAA'}`, fontWeight: 500
                                                        }}>{t('Random.setConfig.docCountry')}</p>
                                                        {
                                                            selectAll ?
                                                                docsBy.map((doc) => (
                                                                    <p style={{
                                                                        fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                                        color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em'
                                                                    }}
                                                                    >{doc}</p>
                                                                )) :
                                                                docsBy ?
                                                                    docsBy.map((doc) => (
                                                                        <p style={{
                                                                            fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                                            color: `${theme ? '#00000070' : '#AAAAAA90'}`,
                                                                        }}
                                                                        >{doc}</p>
                                                                    ))
                                                                    : <p>Select documents by country</p>
                                                        }

                                                    </Col>

                                                    <hr style={{
                                                        width: '90%', border: `1px solid ${theme ? '#00000030' : '#AAAAAA70'}`, margin: '2vh 0 2vh 0'
                                                    }} />

                                                    <Col style={{
                                                        width: '90%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                                        // overflowY:'scroll', height:'6vh'
                                                    }}>
                                                        {
                                                            typeOf === 'RANDOM' ?
                                                                <>
                                                                    <p style={{
                                                                        width: '100%', textAlign: 'left', margin: '1vh 0 1vh 0',
                                                                        color: `${theme ? '#00000090' : '#AAAAAA'}`, fontWeight: 500
                                                                    }}>{t('Random.confirmData.difficulties')}</p>
                                                                    {

                                                                        difficulties ?
                                                                            difficutly.map((value) => (
                                                                                <p style={{
                                                                                    fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                                                    color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em'
                                                                                }}
                                                                                >{value.Difficulty}: {value.Documents} tests</p>
                                                                            ))
                                                                            : <p>Select any difficultie</p>
                                                                    }
                                                                </>

                                                                : <>
                                                                    {
                                                                        specificData ?
                                                                            <>
                                                                                <p style={{
                                                                                    width: '100%', textAlign: 'left', margin: '1vh 0 2vh 0',
                                                                                    color: `${theme ? '#00000090' : '#AAAAAA'}`, fontWeight: 500
                                                                                }}>Id's - {totalSpecifics(specificData)} tests</p>
                                                                                <div
                                                                                    // className={theme ? '' : 'dark-scroll'}
                                                                                    style={{ width: '100%', }}>
                                                                                    {
                                                                                        specificData.map((value) => (
                                                                                            <p style={{
                                                                                                fontWeight: 400, width: '100%', textAlign: 'left', margin: '0.5vh 0 0 0',
                                                                                                color: `${theme ? '#00000070' : '#AAAAAA90'}`, fontSize: '1em'
                                                                                            }}
                                                                                            >{value._id} <b>x</b>{value.quantity}</p>
                                                                                        ))

                                                                                    }
                                                                                </div>
                                                                            </>
                                                                            : <></>
                                                                    }


                                                                </>
                                                        }


                                                    </Col>

                                                    {
                                                        typeOf === 'RANDOM' ?
                                                            <>
                                                                {/* <hr style={{
                                                                    width: '90%', border: `1px solid ${theme ? '#00000030' : '#AAAAAA70'}`, margin: '2vh 0 2vh 0'
                                                                }} /> */}

                                                                <div style={{
                                                                    width: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                    height: '10vh'
                                                                }}>
                                                                    {/* <Button
                                                                        onClick={sendData}
                                                                        disabled={eneable}
                                                                        icon={<IoMdSend style={{ marginRight: '8px', color: theme ? '#00000070' : '#aaa', }} />}
                                                                        style={{
                                                                            backgroundColor: eneable ? theme ? '#dce0e6' : '#aaaaaa40' : theme ? '#a8c1f480' : '#20212399',
                                                                            border: theme ? '' : '0px solid #00000060', color: theme ? '#00000090' : '#aaa',
                                                                            fontWeight: 600,
                                                                            borderRadius: '3vh',
                                                                            margin: '0 8px 0 8px',
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                                            // borderRadius: '1vh 3vh 3vh 1vh',
                                                                            // boxShadow: '0px 0px 5px rgba(0,0,0,0.3)'
                                                                        }}
                                                                    >{t('Ship.Send')}</Button> */}
                                                                </div>
                                                            </>

                                                            : <></>
                                                    }

                                                </div>
                                                : <div style={{ padding: '3%' }}>
                                                    <Empty
                                                        description={<p style={{
                                                            fontWeight: 600, color: `${theme ? '#dce0e6' : '#AAA'}`
                                                        }}>{t('Ship.Empty1')}</p>}
                                                        imageStyle={{ height: '110%' }}
                                                        style={{
                                                            margin: '4vh 0 0 0'
                                                            // width: '68vw'
                                                        }} />
                                                </div>

                                        }
                                    </div>
                                    : <></>
                            }


                        </Row>

                        <Row style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            flexDirection: 'row', width: '100%',
                        }}>
                            {
                                typeOf === 'RANDOM' ? <></>
                                    : <>
                                        {/* <hr style={{ width: '95%', border: `1px solid ${theme ? '#00000020' : '#AAAAAA50'}`, borderRadius: '2vh', margin: '3vh 0 1vh 0' }} /> */}
                                        <div style={{
                                            width: '95%', marginTop: '3vh', display: 'flex',
                                            alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                            flexWrap: 'wrap'
                                        }}>

                                            {
                                                specificDocs ? <SpecificCard
                                                    sendData={sendData} eneable={eneable} tests={totalSpecifics(specificData)}
                                                    curentPage={curentPage} setCurentPage={setCurentPage} data={specificDocs} action={setSpecificData} setSpecificDocs={setSpecificDocs} trainingDocById={trainingDocById} resetIDE={resetIDE} />
                                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                        <Empty
                                                            description={<p style={{
                                                                fontWeight: 600, color: `${theme ? '#dce0e6' : '#AAA'}`
                                                            }}></p>}
                                                            imageStyle={{ height: '110%' }}
                                                            style={{
                                                                margin: '4vh 0 0 0',
                                                                width: '68vw'
                                                            }} />
                                                    </div>
                                            }


                                        </div>

                                    </>
                            }


                        </Row>

                    </div>

                    : <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        {
                            sending ? <TimerApp
                                time={expectedTime}
                            />
                                : <img src={logos.rainbow} style={{ marginTop: '-20vh', width: '8%', margin: 0 }} />
                        }

                    </div>
            }



        </Content >
    )
}


