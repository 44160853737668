import { Route, Routes } from "react-router-dom"
import { PrivateHOC, PrivateRoutes, PublicHOC, PublicRoutes } from "./HOC"

export const AppRouter = () => {
  return (

    <>
      <Routes>
        <Route path="/login"
          element={
            <PublicHOC>
              <PublicRoutes />
            </PublicHOC>
          } />

        <Route path="/"
          element={
            <PublicHOC>
              <PublicRoutes />
            </PublicHOC>
          } />


        <Route path="/*"
          element={
            <PrivateHOC>
              <PrivateRoutes />
            </PrivateHOC>
          } />

      </Routes>
    </>

  )
}
