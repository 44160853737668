import { Button, Row, Tag } from 'antd'
import React, { useEffect } from 'react'
import { handleColors } from '../helpers/functions'
import { BsCheckLg, BsX } from "react-icons/bs";
import { t } from 'i18next';

export const NoEditedAnswers = ({ data, type, theme }) => {
    // 

    return (
        <>
            {
                type === 'DR' ?
                    data ?
                        data.User_DR.Agent_Answer.map((item) => (
                            <>
                                <p style={{
                                    width: '95%', textAlign: 'left', margin: '0 0 1vh 0', color: theme ? '#00000060' : '#AAAAAA80'
                                }}>{item.Name_Label}</p>
                                <Row style={{
                                    width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                    marginBottom: '1vh'
                                }}>
                                    <div style={{
                                        width: '80%', border: theme ? '0px solid #00000040' : '0px solid #000', borderRadius: '0.5vh',
                                        padding: '2%', backgroundColor: theme ? '#fff' : '#2D2E32',
                                        color: theme ? '' : '#AAA'
                                    }}>
                                        {item.Value ? item.Value : 'null'}
                                    </div>
                                    <Button
                                        // onClick={() => handleAnswer(item.Name_Field)}
                                        icon={item.Correct_Answer ? <BsCheckLg size={20} /> : <BsX size={20} />}
                                        style={{
                                            border: '0px solid #00000040', cursor:'default',
                                            margin: '0 0 0 5px', borderRadius: '50%',
                                            display:'flex', alignItems:'center', justifyContent:'center',
                                            backgroundColor: `${handleColors(item.Correct_Answer ? 'SENT' : 'ERROR')}40`,
                                            // border: theme ? `2px solid ${handleColors(item.Correct_Answer ? 'SENT' : 'ERROR')}80` : `2px solid ${handleColors(item.Correct_Answer ? 'SENT' : 'ERROR')}40`,
                                            color: theme ? handleColors(item.Correct_Answer ? 'SENT' : 'ERROR') : `${handleColors(item.Correct_Answer ? 'SENT' : 'ERROR')}60`,
                                        }} />
                                </Row>
                            </>
                        ))
                        : <></>
                    : type === 'AD' ?
                        data ?
                            <Row style={{
                                width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                marginTop: '1vh'
                            }}>
                                <p style={{
                                    width: '', textAlign: 'left', margin: '0 0 1vh 0',
                                    fontWeight: 600, fontWeight: 600, color: theme ? '' : '#AAAAAA80'
                                    // color: '#00000060'
                                }}>{t('Drawer.UserResponse')}: </p>

                                <Tag style={{
                                    width: 'auto', alignItems: 'center', justifyContent: 'center',
                                    width: '40%',
                                    backgroundColor: theme ? `${handleColors(data.User_AD.Agent_Answer ? 'SENT' : 'ERROR')}80` : `${handleColors(data.User_AD.Agent_Answer ? 'SENT' : 'ERROR')}60`,
                                    border: `0px solid ${handleColors(data.User_AD.Agent_Answer ? 'SENT' : 'ERROR')}40`,
                                    color: '#fff',height: '3vh',
                                    fontWeight: 400, borderRadius: '3vh',
                                    display: 'flex'
                                }}>{data.User_AD.Agent_Answer ? 'Accepted' : 'Rejected'}</Tag>


                            </Row>
                            : <></>
                        :
                        data ?
                            <Row style={{
                                width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                marginTop: '1vh'
                            }}>
                                <p style={{
                                    width: '', textAlign: 'left', margin: '0 0 1vh 0',
                                    fontWeight: 600, fontWeight: 600, color: theme ? '' : '#AAAAAA80'
                                    // color: '#00000060'
                                }}>{t('Drawer.UserResponse')}: </p>

                                <Tag style={{
                                    width: 'auto', alignItems: 'center', justifyContent: 'center',
                                    width: '40%',
                                    backgroundColor: theme ? `${handleColors(data.User_TM.Agent_Answer ? 'SENT' : 'ERROR')}80` : `${handleColors(data.User_TM.Agent_Answer ? 'SENT' : 'ERROR')}60`,
                                    border: `0px solid ${handleColors(data.User_TM.Agent_Answer ? 'SENT' : 'ERROR')}40`,
                                    color: '#fff',height: '3vh',
                                    fontWeight: 400, borderRadius: '3vh',
                                    display: 'flex'
                                }}>{data.User_TM.Agent_Answer ? 'Accepted' : 'Rejected'}</Tag>


                            </Row>
                            : <></>
            }
        </>
    )
}
