
import { Layout, Input, Col, Row, Button, message } from "antd"
import { useContext, useEffect, useState } from "react"
import { BsSearch } from "react-icons/bs";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai"
import { FaRegTrashAlt } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { FaSearch, FaTrash } from "react-icons/fa"
import { GrPowerReset } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { t } from "i18next";
import { appContext } from "../../context/AuthContext"

const { Header } = Layout


export const HeaderSpecific = (props) => {

    const { data, trainingDocById, resetIDE, sendData, eneable, tests } = props
    const { theme } = useContext(appContext)

    const [selectedID, setSelectedID] = useState('')

    const handleId = (e) => {
        setSelectedID(e.target.value)
    }

    const handleDocumentByID = (selectedID) => {
        resetIDE()
        trainingDocById(selectedID)
    }

    const handleReset = () => {
        resetIDE()
        setSelectedID()
    }


    return (
        <Header
            className="site-layout-background"
            style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection: 'row',
                // position: 'fixed', zIndex: 1, width: '100%',
                height: '8vh',
                transition: 'all 0.55s ease-in-out', backgroundColor: `${(theme) ? '#f5f5f560' : '#28292C'}`,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                width: '100%'
                // borderBottom: theme ? '2px solid #68789A' : '2px solid #000',
            }}>

            <Row style={{
                display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row',
                width: '40%',
            }}>
                <p style={{
                    fontWeight: 400, color: '#aaaaaa', fontSize: '1.1em',
                    //  margin: '-5vh 0 0 0'
                }}>{t('Specific.message')}<b>{data.length}</b></p>

                <p style={{
                    fontWeight: 400, color: '#aaaaaa', fontSize: '1.1em',
                    //  margin: '-5vh 0 0 0'
                }}>|</p>


                <p style={{
                    fontWeight: 400, color: '#aaaaaa', fontSize: '1.1em',
                    //  margin: '-5vh 0 0 0'
                }}>Total documents to send: <b>{tests}</b></p>
            </Row>


            <Row style={{
                width: '60%',
                display: 'flex',
                alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row'
            }}>
                <Row style={{
                    marginRight: '18px', position: 'relative', display: 'flex',
                    alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                }}>
                    <Input
                        placeholder={t('Searcher.Specific')}
                        onChange={handleId}
                        value={selectedID}
                        style={{
                            width: '30vh', borderRadius: '3vh 0 0 3vh',
                            // height: '4.5vh',
                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                            borderTop: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                            borderRight: theme ? '0px solid #000' : '0px solid #232426',
                            borderBottom: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                            borderLeft: theme ? '1px solid #D9D9D9' : '1px solid #232426',
                            color: `${theme ? '#333' : '#AAA'}`,
                            // fontWeight: 500,
                            backgroundColor: `${theme ? '#ffffff' : '#393b3f'}`,
                        }} />
                    <Button
                        className='prev'
                        icon={<FaSearch size={12} style={{ color: theme ? '#00000070' : '#AAA' }} />}
                        onClick={() => handleDocumentByID(selectedID)}
                        style={{
                            // borderRadius: '0 3vh 3vh 0', height: '4.5vh',
                            // boxShadow: `0px 0px 8px #000000${theme ? '30' : '80'}`, 
                            border: theme ? '' : '1px solid #232426',
                            borderRadius: '50%', position: 'absolute', left: '94%',
                            // border: theme ? '0px solid #000' : '0px solid #000',
                            backgroundColor: theme ? '#CEDCF9' : '#393b3f',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }} />

                </Row>


                <Button
                    onClick={sendData}
                    disabled={eneable}
                    icon={<IoMdSend size={17} style={{ marginRight: '5px', color: theme ? '#00000070' : '#aaa', }} />}
                    style={{
                        backgroundColor: eneable ? theme ? '#dce0e6' : '#aaaaaa40' : theme ? '#a8c1f480' : '#393b3f',
                        border: theme ? '' : '0px solid #00000060', color: theme ? '#00000090' : '#aaa',
                        fontWeight: 600,
                        borderRadius: '3vh',
                        margin: '0 8px 0 8px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}
                >{t('Ship.Send')}</Button>

                <Button
                    onClick={handleReset}
                    icon={<MdDeleteForever size={17} style={{ color: theme ? '#00000070' : '#aaa', }} />}
                    // type="ghost"
                    style={{
                        backgroundColor: eneable ? theme ? '#dce0e6' : '#aaaaaa40' : theme ? '#a8c1f480' : '#393b3f',
                        border: theme ? '' : '0px solid #00000060',
                        color: theme ? '' : '#00000070',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        borderRadius: '50%'
                    }} />
            </Row>


        </Header>
    )
}
