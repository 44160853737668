import { message } from "antd";
import { renewToken } from "../services/apiServices";


export const renew_jwt = async (logout, operation, t) => {

    const expired = (localStorage.getItem('expires'))
    const token = localStorage.getItem("token");

    const now = new Date();
    const expiredDate = new Date(expired);
    const finalDate = new Date(expired)

    expiredDate.setMinutes(expiredDate.getMinutes() - 30);

    
    if (now > finalDate) {
      logout()
      message.info(`${t('Token.Message')}`)
    } 

    else if (now > expiredDate && now < finalDate) {
        try {
            await operation({
                method: "GET",
                url: "/auth/renew",
                headers: { token: `${token}` }
            })
        } catch (error) { 
            console.log(error)
        }
    }

    else if (now < expiredDate) {
      console.log('nothing')
    }

}

export const validate_jwt = async (logout) => {

    const expired = (localStorage.getItem('expires'))

    const now = new Date();
    const expiredDate = new Date(expired);
    const finalDate = new Date(expired)

    expiredDate.setMinutes(expiredDate.getMinutes() - 30);

    
    if (now > finalDate) {
      return false 
    } 

    else if (now < finalDate) {
      return true 
    }

}

