
import logo from '../assets/light-100.jpg'
import darklogo from '../assets/avatar-dark-100.jpg'
import rainbow from '../assets/loader.gif'
import error from '../assets/SVG/error_device.svg'

import login_logo from '../assets/SVG/login-bg.svg'
import metamap from '../assets/SVG/metamap-logo.svg'

export const logos = {
    logo,
    darklogo, 
    rainbow
}

export const images = {
    login_logo,
    metamap,
    error
}