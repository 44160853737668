import { Layout, message } from 'antd'
import React, { useContext, useEffect } from 'react'
import { HeaderApp } from '../Layouts/home/HeaderApp'
import { FooterApp } from '../Layouts/home/FooterApp'
import { ContentApp } from '../Layouts/home/ContentApp'
import { appContext } from '../context/AuthContext'
import { renew_jwt } from '../helpers/renew_jwt'
import useAxios from '../Hooks/UseAxios'
import { t } from 'i18next'


export const HomePage = () => {

  // const theme = JSON.parse( localStorage.getItem('theme') )
  const { theme, logout } = useContext(appContext)
  const { response, loading, error, operation } = useAxios()



  useEffect(() => {
    const changeBody = () => {
      // document.body.style.backgroundColor = `${theme ? '#fff' : '#2b2d31'}`;'
      document.body.style.background = `linear-gradient(to bottom, ${theme ? '#FFF' : '#2b2d31'} , ${theme ? '#FAFAFA' : '#2b2d31'} )`
      // document.body.style.background = 'conic-gradient(from 0deg at 50% 50%, #F5F5F5 0%, #F5F5F5 45deg, #FFF 45deg, #FFF 135deg, #F5F5F5 135deg, #F5F5F5 225deg, #FFF 225deg, #FFF 315deg, #F5F5F5 315deg, #F5F5F5 360deg)'


    }
    changeBody()
  }, [theme])

  useEffect(() => {
    renew_jwt(logout, operation, t)
  }, [])

  useEffect(() => {
    if (response) {
      console.log(response.data.data.token)
      localStorage.setItem("token", response.data.data.token)
    }
  }, [response])




  return (
    <Layout style={{ backgroundColor: theme ? 'transparent' : 'transparent' }}
      className="background-app"
    >
      <HeaderApp text={<p><b>Meta</b>Map</p>} module={'home'} />
      <ContentApp />
      <FooterApp />
    </Layout>

  )
}
